import {
  Checkbox,
  InputAdornment,
  ListSubheader,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useMemo, useState } from "react";
import IDropdownProps from "../../../models/IDropdownProps";
import styles from "./Dropddown.module.scss";
import SearchIcon from "@mui/icons-material/Search";

const Dropdown = ({
  label,
  options,
  defaultLabel,
  minWidth,
  selectedValue = null,
  isDropdownOutlineRequired = true,
  onChange,
  height,
  overlayWidth,
  id,
  register,
  errorMessage,
  isCountryList = false,
  disabled,
  editForm,
  borderRightRemove = false,
  isCountryOrigin = false,
  multiple = false,
  disablePortal,
  mobileNumberField,
  customDropdownStyles,
  setSelectedvalue,
  isfilters = false,
  isContactNumber,
  tabIndex = -1,
  isMarketplace = false,
  autoFocus = false,
  isCurrentMenuItemDisabled,
  searchField = false,
  itemStyles,
  setIsDropdownOpened,
}: IDropdownProps) => {
  const [value, setValue] = useState(selectedValue ? selectedValue : "");
  const [searchText, setSearchText] = useState("");

  const [multipleValues, setMultipleValues] = useState<string[]>(
    multiple
      ? selectedValue
        ? selectedValue?.map((option: any) => option.value)
        : []
      : []
  );
  useEffect(() => {
    if (multiple && selectedValue) {
      setMultipleValues(selectedValue.map((option: any) => option.value));
    }
  }, [selectedValue]);

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (event: any) => {
    setSelectedvalue && setSelectedvalue(event.target.value);
    let value = event?.target?.value;
    console.log(value, "value");

    let item;
    if (mobileNumberField) {
      item = options.find((option: any) => option?.dial_codes === value);
    } else {
      item = options.find((option: any) => option?.value === value);
    }

    setValue(value);

    onChange(item);
  };

  useEffect(() => {
    if (!selectedValue) return;
    setValue(selectedValue);
    onChange(selectedValue);
  }, [selectedValue]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchText("");
    if (setIsDropdownOpened) setIsDropdownOpened(true);
  };

  const handleMultipleModeChanges = (event: any) => {
    const {
      target: { value },
    } = event;
    if (value?.includes("Select All")) {
      if (multipleValues?.length != options?.length) {
        const value = options?.map((option: any) => option?.value);
        setMultipleValues(
          typeof value === "string" ? value?.split(",") : value
        );
        onChange(options);
      } else {
        setMultipleValues([]);
        onChange([]);
      }
    } else {
      let items = options.filter((option: any) => {
        if (value?.includes(option.value)) {
          return option;
        }
      });
      setMultipleValues(typeof value === "string" ? value.split(",") : value);
      onChange(items);
    }
  };

  const singleModerender = (value: any) => {
    let val =
      mobileNumberField || isCountryOrigin || isfilters || searchField
        ? options?.find((ele: any) => ele.value === selectedValue?.value)
        : options?.find((ele: any) => ele.value === value?.value)?.name;
    setValue(val ?? defaultLabel);

    return mobileNumberField ? (
      <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
        <img
          style={{ width: "25px", height: "25px" }}
          src={val?.flag ?? defaultLabel?.flag}
          alt=""
        />
        <p>{val?.dial_codes ?? defaultLabel?.dial_codes}</p>
      </div>
    ) : isCountryOrigin ? (
      <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
        {(val?.flag || defaultLabel?.flag) && (
          <img
            style={{ width: "25px", height: "25px" }}
            src={val?.flag ?? defaultLabel?.flag}
            alt=""
          />
        )}
        <div>{val?.display_name ?? defaultLabel}</div>
      </div>
    ) : isfilters ? (
      <div style={{ display: "flex", gap: "7px", alignItems: "center" }}>
        <div>{val?.name ?? defaultLabel}</div>
      </div>
    ) : (
      val?.flag ?? defaultLabel
    );
  };
  const multipleModeRender = (value: any) => {
    return value?.length > 0 ? value?.join(", ") : defaultLabel;
  };
  let menuProps: any = {
    disablePortal: disablePortal ?? false,
    PaperProps: {
      sx: {
        width: overlayWidth,
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15) !important",
        maxHeight: "255px",
        "& .MuiMenuItem-root": {
          color: "#1C1919",
          fontSize: "14px",
          letterSpacing: "0.5px",
          fontFamily: "UrbanistRegular",
          overflow: "hidden",
          display: "block",
          textOverflow: "ellipsis",
          padding: "8px 20px",
          margin: "2px 0",
        },
      },
    },
  };
  if (isContactNumber) {
    menuProps["anchorOrigin"] = {
      vertical: "bottom",
      horizontal: "left",
    };
    menuProps["transformOrigin"] = {
      vertical: "top",
      horizontal: "left",
    };
  }
  const containsText = (text: any, searchText: any) => {
    const normalizedText = typeof text?.value === 'string' ? text.value.toLowerCase() : typeof text === 'string' ? text.toLowerCase() : '';
    const normalizedSearchText = typeof searchText === 'string' ? searchText.toLowerCase() : '';
    return normalizedText.includes(normalizedSearchText);
  };

  const displayedOptions = useMemo(
    () =>
      options &&
      options?.filter((option: any) => containsText(option, searchText)),
    [searchText]
  );
  if (isCountryOrigin || mobileNumberField || searchField) {
    menuProps["autoFocus"] = false;
  }
  return (
    <>
      <div
        className={`${styles.inputLabelStyles} ${errorMessage ? styles.errorStyle : null
          } ${editForm ? styles.disableLabelStyles : null}`}
      >
        {label}
      </div>
      <FormControl
        className={`${styles.formControl} ${editForm ? styles.editFormStyles : ""
          }`}
        style={{ width: `${minWidth}` }}
      >
        <Select
          autoFocus={autoFocus}
          id={id}
          style={{
            height: `${height}`,
            width: `${minWidth}`,
            borderRight: `${borderRightRemove && "none"}`,
            borderTopRightRadius: `${borderRightRemove && 0}`,
            borderBottomRightRadius: `${borderRightRemove && 0}`,
            backgroundColor: disabled ? "#c2c3c547" : "",
          }}
          tabIndex={tabIndex}
          data-testid={"dropdown-bundle-options"}
          disabled={disabled}
          onChange={multiple ? handleMultipleModeChanges : handleChange}
          renderValue={multiple ? multipleModeRender : singleModerender}
          displayEmpty={true}
          value={
            multiple
              ? multipleValues
              : selectedValue === defaultLabel
                ? ""
                : mobileNumberField
                  ? selectedValue?.dial_codes
                  : selectedValue?.value
          }
          multiple={multiple}
          onClose={toggleDropdown}
          placeholder={"test"}
          onOpen={toggleDropdown}
          title={
            isMarketplace
              ? selectedValue
                ? selectedValue?.name
                : ""
              : selectedValue
                ? selectedValue.value
                : "enter"
          }
          className={`${styles.select}
          ${mobileNumberField ? styles.mobileSelect : null}
          ${isDropdownOutlineRequired ? styles.selectWithBorder : {}} ${editForm ? styles.editFormBorderStyles : ""
            }
                        ${value === defaultLabel ? styles.placeholder : ""}
                        ${isOpen && isDropdownOutlineRequired
              ? styles.focused
              : null
            }
                        ${errorMessage && isDropdownOutlineRequired
              ? styles.errorBorder
              : null
            } ${tabIndex > 0 ? styles.outline : null}`}
          sx={{
            width: minWidth,
            ".Mui-focused": {},
            "& .MuiOutlinedInput-notchedOutline": {
              border: "unset !important",
            },
            "& .MuiSelect-outlined": {
              padding: "0 !important",
              paddingRight: "32px !important",
              color:
                (!value && !multiple) ||
                  (multiple && multipleValues?.length === 0) ||
                  (!multiple &&
                    options?.find((ele: any) => {
                      return (
                        (ele.name || ele.value) ===
                        (typeof value === "object" ? value.value : value)
                      );
                    })?.value === undefined)
                  ? "#949494 !important"
                  : editForm
                    ? "rgb(148, 148, 148) "
                    : " #1c1919 !important",
              fontFamily: "UrbanistRegular !important",
              fontSize: "14px !important",
              letterSpacing: "0.5px !important",
              lineHeight: "20px !important",
            },
            "& .MuiSvgIcon-root": {
              display: "none !important",
            },
          }}
          MenuProps={menuProps}
        >
          {(isCountryOrigin || mobileNumberField || searchField) &&
            options &&
            options?.length > 0 && (
              <ListSubheader>
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="outlined"
                  autoFocus
                  placeholder="Search by country name ..."
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      fontFamily: "UrbanistRegular",
                      color: "#1C1919",
                      lineHeight: "22px",
                      letterSpacing: "0.5px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      border: "1px solid #949494",
                      padding: "0px 10px",
                    },
                    "& input": {
                      fontFamily: "UrbanistRegular",
                      color: "#1C1919",
                      lineHeight: "22px",
                      letterSpacing: "0.5px",
                      fontSize: "14px",
                    },
                  }}
                />
              </ListSubheader>
            )}
          {multiple && options && options?.length > 0 && (
            <MenuItem
              value="Select All"
              key={`menuItem_all`}
              title="Select All"
            >
              {multiple && (
                <Checkbox
                  checked={multipleValues?.length === options?.length}
                />
              )}
              Select All
            </MenuItem>
          )}

          {options &&
            options?.length > 0 &&
            ((isCountryOrigin || mobileNumberField || searchField) &&
              searchText?.length > 0
              ? displayedOptions
              : options
            )?.map((option: any, ind: number) => (
              <MenuItem
                disabled={
                  isCurrentMenuItemDisabled &&
                  isCurrentMenuItemDisabled(option.value || option.name)
                }
                onClick={() => {
                  id === ("equalto" || "orderingOption") &&
                    handleChange({ target: { value: option.value } });
                }}
                data-testid={option.value}
                value={mobileNumberField ? option.dial_codes : option.value}
                key={`menuItem_${ind}`}
                title={isMarketplace ? option?.name : option?.value}
                style={itemStyles && itemStyles}
              >
                {multiple && (
                  <Checkbox
                    checked={multipleValues.indexOf(option.value) > -1}
                  />
                )}
                {isCountryList ? (
                  <div className={styles.childRow}>
                    <img
                      className={styles.countryIcon}
                      src={option.flag}
                      alt={option.flag}
                    />
                    <div className={styles.rowTextStyle}>
                      {mobileNumberField ? option.dial_codes : option.name}
                    </div>
                    {!isCountryOrigin && (
                      <div className={styles.rowSeperator}></div>
                    )}
                    {!isCountryOrigin && (
                      <div className={styles.rowTextStyle}>{option.value}</div>
                    )}
                  </div>
                ) : isMarketplace ? (
                  option?.name
                ) : option?.value?.length > 50 ? (
                  option?.value?.slice(0, 50) + "..."
                ) : (
                  option?.value
                )}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {errorMessage ? (
        <div className={styles.errorMessage}>{errorMessage.toString()}</div>
      ) : (
        ""
      )}
    </>
  );
};

export default Dropdown;
