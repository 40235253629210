import styles from "./DatePicker.module.scss";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IDatePickerProps } from "../../../models/IDatePickerProps";
import dayjs from "dayjs";

const DatePickerComponent = ({
  date,
  setDate,
  handleDateChange,
  singleDate,
  errorMessage,
  label,
  showOuterError,
  hideAsterisk,
  placeholder,
  disablePast = true,
  disableFuture = true,
  maxWidth,
}: IDatePickerProps) => {
  const currentDate = new Date();
  const handleChange = (selectedDate: Date | null) => {
    if (selectedDate && dayjs(selectedDate).isValid()) {
      // setDate(selectedDate);
      // const adjustedDate = new Date(selectedDate.toLocaleString());
      // if (adjustedDate.getFullYear()) {
      // setDate(adjustedDate);
      handleDateChange(selectedDate);
    } else {
      handleDateChange(null);
    }
  };
  return (
    <div className={styles.datePickerContainer}>
      {label && (
        <p
          className={
            date?.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)
              ? styles.error
              : styles.label
          }
        >
          {label} {hideAsterisk ? null : <sup>*</sup>}
        </p>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={date ? date : null}
          data-testid="datePicker"
          dayOfWeekFormatter={(day) => day}
          disablePast={disablePast}
          onChange={handleChange}
          showDaysOutsideCurrentMonth
          sx={{
            "&.MuiInputBase-root": {
              maxWidth: "100%",
            },
          }}

          slotProps={{
            textField: {
              placeholder: placeholder ? placeholder : "DD/MM/YYYY",
            },
          }}
          disableFuture={disableFuture}
        />
      </LocalizationProvider>
      {!showOuterError && (
        <p
          data-testid="errorMessage"
          className={
            date?.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0) ||
              date === undefined
              ? styles.errorMessage
              : styles.helperText
          }
        >
          {(date?.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0) ||
            date === undefined) &&
            errorMessage}
        </p>
      )}
      {!showOuterError && (
        <p
          data-testid="errorMessage"
          className={!singleDate ? styles.errorMessage : styles.helperText}
        >
          {singleDate && errorMessage}
        </p>
      )}
    </div>
  );
};

export default DatePickerComponent;
