/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import cancelAction from "../../../assets/images/cancelToast.svg";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import Breadcrumb from "../../../common/components/breadCrumb/BreadCrumb";
import Button from "../../../common/components/button/Button";
import CheckboxButton from "../../../common/components/checkbox/CheckboxButton";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import FileUploadCard from "../../../common/components/fileUploadCard/FileUploadCard";
import InputField from "../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import RadioButton from "../../../common/components/radio/RadioButton";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import {
  DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO,
  constants,
} from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import {
  APIMethods,
  adminEndPoints,
} from "../../../common/constants/urlConstants";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../services/useAxios";
import useFetchCountries from "../../../services/useCountry";
import useFileUpload from "../../../services/useUploadImage";

import styles from "./AdminSupplierSetupForm.module.scss";
import { setToastStatusApproval } from "../../../reducerSlices/toastStatusSlice";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../reducerSlices/loaderSlice";
import { getCountryByDialCode } from "../../../utils/functions";
const defaultUserType = constants.userTypesListSupplier.find(
  (el) => el.name.toLowerCase().trim() === "admin"
);
const AdminSupplierBusinessSetupForm = () => {
  const dispatch = useDispatch();
  const { countriesList } = useFetchCountries();
  // const {id} = useLocation().search.split("=")[1];
  const { businessId, supplierEdit } = useParams();
  const [userId] = useState(businessId);
  const { state: historyState = {} } = useLocation();
  let customerId;
  let previousPath;
  if (historyState) {
    customerId = historyState?.customerId;
    previousPath = historyState?.previousPath;
  }
  const isSupplierCustomer = previousPath === "/admin/users";
  const [editSupplierData, setEditSupplierData] = useState<any>({});
  const [existingSuppliersFetchedList, setExistingSuppliersFetchedList] =
    useState<any>([]);
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [licenseFileError, setLicenseFileError] = useState(false);
  const [searchedProduct, setSearchedProduct] = useState("");
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [selectedSuggestedItem, setSelectedSuggestedItem] = useState<any>();
  const [logoImageFile, setLogoImageFile] = useState("");
  const [logoImageFileUrl, setLogoImageFileUrl] = useState("");
  const [contractImageFile, setContractImageFile] = useState("");
  const [contractImageFileUrl, setContractImageFileUrl] = useState("");
  const [licenseImageFile, setLicenseImageFile] = useState("");
  const [licenseImageFileUrl, setLicenseImageFileUrl] = useState("");
  const [ibanValid, setIbanValid] = useState<string>("");
  const [vatImageFile, setVATImageFile] = useState("");
  const [vatImageFileUrl, setVATImageFileUrl] = useState("");
  const navigate = useNavigate();
  const [showAccountName, setShowAccountName] = useState<boolean>(false);
  const [deliveryLocations, setDeliveryLocations] = useState<{ id: string }[]>(
    []
  );
  const [selectedOutlet, setSelectedOutlet] = useState<any>();
  const [contractFileError, setContractFileError] = useState(false);
  const [vatFileError, setVatFileError] = useState(false);
  const [userType, setUserType] = useState<any>();
  const [showOutletDropdown, setshowOutletDropdown] = useState<boolean>(false);
  const [isOutletChoosed, setisOutletChoosed] = useState<boolean>(false);
  const [isStaffUser, setIsStaffUser] = useState<boolean>(false);
  const [rolesOptions, setRolesOptions] = useState(constants.rolesOptions);
  const breadcrumbItems = [{ label: "Edit account details" }];
  const [open, setOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [approvalStatus, setApprovalStatus] = useState(false);
  const [editForm, setEditForm] = useState<boolean>(false);
  const [mobileNumberCode, setMobileNumberCode] = useState<any>();
  const [selectedLocations, setSelectedLocations] = useState<any>([]);
  const [businessStoreId, setBusinessStoreId] = useState<any>();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const validateUAEIBAN = (iban: any) => {
    const ibanPattern = /^AE\d{2}\s?\d{3}\s?\d{16}$/;
    return ibanPattern.test(iban);
  };
  const schema = yup
    .object({
      password:
        userId || businessId
          ? yup.string().optional()
          : yup
            .string()
            .required("Enter password")
            .test(
              "password",
              "Must be 8-14 characters with upper, lower, number, and special character.",
              (value) => {
                return passwordRegex.test(value || "");
              }
            ),
      confirmPassword:
        userId || businessId
          ? yup.string().optional()
          : yup
            .string()
            .required("Enter confirm password")
            .oneOf([yup.ref("password"), ""], "Passwords must match"),
      businessName: yup.string().required("Enter business name"),
      firstName: yup.string().required("Enter first  name"),
      lastName: yup.string().required("Enter last  name"),
      emailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter a valid email"),
      userEmailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter a valid email"),
      licenseNo: yup.string().required("Enter license number"),
      contactName: yup.string().required("Enter contact name"),
      phNo: yup
        .number()
        .positive()
        .required("Enter phone number")
        .typeError("Enter phone number"),
      countryName: yup.object().required("Select country"),
      paymentMethod: yup
        .string()
        .oneOf(["cod", "online", "both"])
        .required("Select a payment method"),
      address: yup.string().required("Enter address"),
      emirates: yup.object().required("Select emirates"),
      area: yup.string().required("Enter area name"),
      country: yup.object().required("Select country"),
      licenseFile: yup.mixed().nullable(),
      logo: yup.mixed().optional().nullable(),
      contract: yup.mixed().nullable(),
      iban: yup.string().when("paymentMethod", {
        is: (paymentMethod: string) =>
          paymentMethod === "online" || paymentMethod === "both",
        then: (schema) =>
          schema
            .required("Enter iban number")
            .test("is-iban", "Invalid IBAN format", (value) =>
              validateUAEIBAN(value)
            ),
        otherwise: (schema) => schema.optional(),
      }),
      accountName: yup.string().when("paymentMethod", {
        is: (paymentMethod: string) =>
          paymentMethod === "online" || paymentMethod === "both",
        then: (schema) => schema.required("Enter account name"),
        otherwise: (schema) => schema.optional(),
      }),
      minimumOrderValue: yup
        .number()
        .positive()
        .test(
          "is-decimal",
          "Only integers is allowed ( Ex: valid - 100 , invalid - 100.2)",
          (value) => Number.isInteger(value)
        )
        .required("Enter minimum order value")
        .typeError("Enter minimum order value"),
      deliveryAddress: yup
        .array()
        .of(yup.object())
        .min(1, "Select a delivery address")
        .required("Select a delivery address"),
      vatNo: yup
        .string()
        .optional()
        .nullable()
        .matches(
          constants.formaValidations.VAT_REGEX,
          "VAT number must contain alphabets or numeric only"
        ),
      vatFile: yup.mixed().optional().nullable(),
      chooseOutlet: yup.boolean().optional(),
      outlet: yup.object().when("chooseOutlet", {
        is: () => isOutletChoosed,
        then: (schema) => schema.required("Select an outlet"),
        otherwise: (schema) => schema.optional(),
      }),
      userType: yup.object().required("Select user type"),
      // maxOrderLimit: yup
      //   .number()
      //   .positive()
      //   .when("userType", {
      //     is: (user: any) => user?.value === "Staff",
      //     then: (schema) => schema.required("Enter order limit"),
      //     otherwise: (schema) => schema.optional(),
      //   }),
    })
    .required();

  const { state } = useLocation();
  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    control,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const values = getValues();
  const [ibanInputDisabled, setIbanInputDisabled] = useState(true);
  const handlePaymentMethodChange = (newValue: "cod" | "online" | "both") => {
    if (newValue === "cod") {
      setValue("iban", "");
      setValue("accountName", "");
      setIbanInputDisabled(true);
      setShowAccountName(false);
    } else {
      setIbanInputDisabled(false);
      setShowAccountName(true);
    }
    setValue("paymentMethod", newValue);
  };
  useEffect(() => {
    setTimeout(() => {
      if (getValues().paymentMethod !== "cod" && editForm) {
        setIbanInputDisabled(false);
      }
    }, 200);
  }, [editForm]);

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "iban") {
        setValue(name, text?.toUpperCase());
      }
      if (name === "countryName") {
        setValue(name, text);
      } else if (name === "userEmailID") {
        setValue(name, text);
      } else if (name === "country" || name === "emirates") {
        setValue(name, text);
      } else if (name === "businessName") {
        setSearchedProduct(text);
        // debouncedGetBuyersList();
      } else if (
        name === "logo" ||
        name === "licenseFile" ||
        name === "contract" ||
        name === "vatFile"
      ) {
        setValue(name, text);
      } else if (name === "deliveryAddress") {
        setValue(name, text);
        if (Array.isArray(text)) {
          const deliveryLocationsData = text?.map((deliveryLocation: any) => ({
            id: deliveryLocation?.id,
          }));
          setDeliveryLocations(deliveryLocationsData);
        }
      }
      if (name === "businessName") {
        setSearchedProduct(text);
      } else if (name === "chooseOutlet") {
        if (text?.target?.checked) {
          setValue(name, true);
          setshowOutletDropdown(!showOutletDropdown);
          setisOutletChoosed(!isOutletChoosed);
        }
      } else if (name === "userType") {
        if (text?.value === "Staff") {
          setIsStaffUser(true);
        } else {
          setIsStaffUser(false);
        }
      }
      if (isSubmitted) {
        trigger(name);
      }
    },
    [setValue, isSubmitted]
  );

  useEffect(() => {
    if (isSubmitted) {
      if (!licenseImageFile && !licenseImageFileUrl) {
        setLicenseFileError(true);
      }
      if (!contractImageFile && !contractImageFileUrl) {
        setContractFileError(true);
      }
      if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
        setVatFileError(false);
      }
    }
  }, [errors, isSubmitted]);

  const getData = useAxios({
    axiosParams: {
      url: `${userId ? `store/supplier/${userId}` : `store/supplier/${businessId}`
        }`,
    },
    method: APIMethods.get,
  });

  const getSupplierCustomerData = useAxios({
    axiosParams: {
      url: `admin/supplier/${businessId}/customer/${customerId}`,
    },
    method: APIMethods.get,
  });
  const [getCurrentProductStatus, setCurrentProductStatus] = useState("");

  // useEffect(() => {
  //   const getFlag: any =
  //     editSupplierData &&
  //     editSupplierData?.customer_businesses &&
  //     countriesList &&
  //     countriesList.length > 0 &&
  //     countriesList.filter(
  //       (country) =>
  //         country?.dial_codes ===
  //         (editSupplierData?.customer_businesses[0]?.phone_number?.split(
  //           "#"
  //         )[0] as any)
  //     )[0];
  //   if (getFlag) {
  //     setMobileNumberCode({
  //       dial_codes:
  //         editSupplierData?.customer_businesses[0]?.phone_number?.split(
  //           "#"
  //         )[0] as any,
  //       flag: getFlag && getFlag?.flag,
  //     });
  //   }
  // }, [countriesList, editSupplierData]);
  const getUserDetails = async () => {
    let response: any;
    if (isSupplierCustomer) {
      dispatch(updateIsLoading(true));
      response = await getSupplierCustomerData.fetchData();
      dispatch(updateIsLoading(false));
    } else if (userId || businessId) {
      response = await getData.fetchData();
    }
    if (response?.status === "SUCCESS") {
      const userData = response?.data;
      let customerData: any;
      const addressDetails = userData?.business_stores?.[0];
      if (isSupplierCustomer) {
        customerData = userData?.customer;
      } else {
        customerData = userData?.customers?.[0];
      }
      setBusinessStoreId(addressDetails?.id);
      setCurrentProductStatus(userData?.status);
      setEditSupplierData(userData);
      setValue("businessName", userData?.business_name);
      setValue("contactName", addressDetails?.contact_name);
      setValue("firstName", customerData?.first_name);
      setValue("lastName", customerData?.last_name);
      // setValue("contactName", customerData?.contact_name);
      setValue("userEmailID", customerData?.email);
      setValue("emailID", userData?.email_id);
      setValue("phNo", addressDetails?.phone_number?.split("#")[1]);
      setValue("contract", userData?.contract_file);
      setValue("logo", userData?.logo_file);
      setValue("licenseNo", userData?.license_number);
      setValue("licenseFile", userData?.license_file);
      setValue("vatNo", userData?.vat_number);
      setValue("vatFile", userData?.vat_file);
      setValue("minimumOrderValue", +userData?.min_order_value);
      setValue("country", addressDetails?.address?.country);
      setValue("address", addressDetails?.address?.address);
      setValue("emirates", addressDetails?.address?.emirate);
      setValue("area", addressDetails?.address?.area);
      setValue("userType", customerData?.role);
      setUserType({
        ...customerData?.role,
        name: customerData?.role?.role,
      });
      // if (userData?.customer_businesses[0]?.roles?.role) {
      //   setValue("maxOrderLimit", userData?.min_order_value);
      // }
      setLicenseImageFileUrl(userData?.license_file);
      setVATImageFileUrl(userData?.vat_file);
      setLogoImageFileUrl(userData?.logo_file);
      setContractImageFileUrl(userData?.contract_file);
      if (userData?.iban) {
        setValue("iban", userData?.iban);
      }
      if (userData?.account_name) {
        setValue("accountName", userData?.account_name);
      }
      const onlineOptionExists =
        userData &&
        userData?.payment_options &&
        userData?.payment_options?.length > 0 &&
        userData?.payment_options?.some(
          (option: any) => option?.name === "ONLINE"
        );

      const codOptionExists =
        userData &&
        userData?.payment_options &&
        userData?.payment_options?.length > 0 &&
        userData?.payment_options?.some(
          (option: any) => option?.name === "COD"
        );

      const includesOnlineAndCod = onlineOptionExists && codOptionExists;

      if (includesOnlineAndCod) {
        setValue("paymentMethod", "both");
        setShowAccountName(true);
      } else if (onlineOptionExists) {
        setValue("paymentMethod", "online");
      } else if (codOptionExists) {
        setValue("paymentMethod", "cod");
      }
    }
  };

  useEffect(() => {
    if (userId || businessId) {
      let country = null;
      const addressDetails =
        editSupplierData?.business_stores?.[0] &&
        editSupplierData?.business_stores?.[0];
      if (addressDetails) {
        country = countriesList?.find(
          (ele: any) => ele?.iso_2 === addressDetails?.address?.country?.iso_2
        );
        if (country) {
          const list = country?.emirates?.filter((item: any) =>
            editSupplierData?.deliverable_locations?.some(
              (newItem: any) => item?.id === newItem?.id
            )
          );
          const listWithValue = list?.map((item: any) => ({
            ...item,
            value: item.name,
          }));
          setSelectedCountry(country);
          setSelectedLocations(listWithValue);
          setValue("deliveryAddress", listWithValue);
        }
        setValue(
          "countryName",
          getCountryByDialCode(
            addressDetails?.phone_number?.split("#")[0],
            countriesList
          )
        );
        setMobileNumberCode(
          getCountryByDialCode(
            addressDetails?.phone_number?.split("#")[0],
            countriesList
          )
        );
      }
      if (
        (userId || businessId) &&
        country &&
        addressDetails?.address?.emirate
      ) {
        setSelectedEmirate(
          country?.emirates?.find(
            (ele: any) => ele?.id === addressDetails?.address?.emirate?.id
          )
        );
      }
    }
  }, [countriesList, businessId, editSupplierData, userId]);

  useEffect(() => {
    if (!businessId) {
      setMobileNumberCode(getCountryByDialCode("+971", countriesList));
    }
  }, [countriesList, businessId, userId]);

  useEffect(() => {
    getUserDetails();
  }, [userId, businessId]);
  useEffect(() => {
    setValue("userType", defaultUserType || {});
    setUserType(defaultUserType);
  }, []);

  const handleSuggestion = (item: any) => {
    if (item) {
      const updatedItem = {
        ...item,
        country: {
          ...item?.country,
          emirates: [item?.emirate],
          value: item?.country?.name,
        },
        outletList: [
          {
            ...item?.country,
            value: item?.country?.name,
            address: item?.address,
            area: item?.area,
            emirates: item?.emirate,
          },
        ],
      };
      setSelectedSuggestedItem(updatedItem);
    }
    setValue("businessName", item?.business_name);
    setValue("contactName", item?.contact_name);
    setValue("countryName", item?.country);
    setValue("phNo", item?.business_phone);
    setValue("emailID", item?.business_email);
    setValue("licenseNo", item?.license_number);
    setValue("vatNo", item?.vat_number);
    setInputFocused(false);
  };

  // const getApprovalListOfBuyers = useAxios({
  //   axiosParams: {
  //     // url: `store/business-setup/suppliers?q=${searchedProduct}`,
  //     url: `store/business-setup/suppliers?q=bs_01HC4WRJDSXR64QGEVKDV6NMBW`,
  //     data: {
  //       q: values?.businessName,
  //       status: "",
  //     },
  //     method: "POST",
  //   },
  // });

  // const debouncedGetBuyersList = loadash.debounce(async () => {
  //   if (searchedProduct !== "") {
  //     const response: any = await getApprovalListOfBuyers?.fetchData();
  //     setExistingSuppliersFetchedList(response?.data?.suppliers);
  //   } else {
  //     setExistingSuppliersFetchedList([]);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   debouncedGetBuyersList();

  //   return () => {
  //     debouncedGetBuyersList.cancel();
  //   };
  // }, [searchedProduct]);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getVatFiles = async () => {
      if (vatImageFile) {
        const response: any = await uploadFile(vatImageFile);
        setTimeout(() => {
          setVATImageFileUrl(response?.data[0]);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getVatFiles();
  }, [vatImageFile]);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getLicenseFiles = async () => {
      if (licenseImageFile) {
        const response: any = await uploadFile(licenseImageFile);
        setTimeout(() => {
          setLicenseImageFileUrl(response?.data[0]);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLicenseFiles();
  }, [licenseImageFile]);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getContractFiles = async () => {
      if (contractImageFile) {
        const response: any = await uploadFile(contractImageFile);
        setTimeout(() => {
          setContractImageFileUrl(response?.data[0]);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getContractFiles();
  }, [contractImageFile]);

  useEffect(() => {
    const { uploadFile } = useFileUpload();
    const getLogoFiles = async () => {
      if (logoImageFile) {
        const response: any = await uploadFile(logoImageFile);
        setTimeout(() => {
          setLogoImageFileUrl(response?.data[0]);
        }, DEFAULT_WAIT_TIME_FOR_RENDERING_IMAGE_FROM_MINIO);
      }
    };
    getLogoFiles();
  }, [logoImageFile]);

  const handleCountryClick = (selectedOption: ICountry) => {
    const emirates =
      selectedOption &&
      selectedOption?.emirates?.map((emirate: ICountry) => ({
        ...emirate,
        value: emirate?.name,
      }));
    setEmiratesList(emirates);
  };

  // const existing_business = {
  //   business_id: selectedSuggestedItem && selectedSuggestedItem?.business_id,
  //   contact_name: values?.contactName,
  //   phone_number: values?.phNo,
  // };
  const isVatFileChanged = editSupplierData?.vat_file === vatImageFileUrl;
  const isContractFileChanged =
    editSupplierData?.contract_file === contractImageFileUrl;
  const isTradeLicenceFileChanged =
    editSupplierData?.license_file === licenseImageFileUrl;

  function getPayloadObj(values: any) {
    const isVatNumberChanged = editSupplierData?.vat_number === values?.vatNo;
    const isLicenceNumberChanged =
      editSupplierData?.license_number === values?.licenseNo;

    return {
      business_name: values?.businessName,
      email_id: values?.emailID,
      ...(!isLicenceNumberChanged && { license_number: values?.licenseNo }),
      ...(!isTradeLicenceFileChanged && {
        license_file: licenseImageFileUrl && licenseImageFileUrl,
      }),

      ...(!isVatNumberChanged && { vat_number: values?.vatNo }),
      ...(!isVatFileChanged && {
        vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
      }),

      logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
      ...(!isContractFileChanged && {
        contract_file: contractImageFileUrl && contractImageFileUrl,
      }),

      min_order_value: +values?.minimumOrderValue,
      iban: values?.iban ?? "",
      deliverable_locations: deliveryLocations,
      // status: approvalStatus
      //   ? "approved"
      //   : supplierEdit
      //   ? "approved"
      //   : "review",
      payment_options:
        values?.paymentMethod === "both"
          ? ["cod", "online"]
          : [values?.paymentMethod],
      account_name: values?.accountName ?? "",
      ware_houses: [
        {
          id: businessStoreId && businessStoreId,
          address: values?.address,
          area: values?.area,
          emirate: {
            id: selectedEmirate?.id,
          },
          country: {
            iso_2: selectedCountry?.iso_2,
          },
          contact_name: values?.contactName,
          phone_number: `${(values?.countryName as any)?.dial_codes}#${values?.phNo
            }`,
        },
      ],
      super_admin: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.userEmailID
      },

    };
  }
  const updateSupplierApiCall = async (data?: any) => {
    let response: any;
    if (data && Object.keys(data)?.length > 0) {
      console.log(values.userEmailID, "values.userEmailIDvalues.userEmailIDvalues.userEmailIDvalues.userEmailID")
      response = await updateCurrentBusinessSetup.fetchData({
        axiosParams: {
          url: `${getCurrentProductStatus === "approved" ? "admin" : "store"
            }/supplier/${businessId && businessId}`,
          data: getPayloadObj(data),
        },
        method: "PUT",
      });
    } else {
      response = await updateCurrentBusinessSetup?.fetchData();
    }
    if (response?.status === "SUCCESS") {
      return response;
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };
  const approveSupplier = useAxios({
    axiosParams: {
      url: `store/business-setup/${businessId}/approve`,
      data: {
        status: "approved",
      },
    },
    method: "POST",
  });
  //reject  api
  const rejectSupplier = useAxios({
    axiosParams: {
      url: `store/business-setup/${businessId}/approve`,
      data: {
        status: "rejected",
      },
    },
    method: "POST",
  });
  const rejectSelectedSupplier = async () => {
    if (!licenseImageFile && !licenseImageFileUrl) {
      setLicenseFileError(true);
    }
    if (!contractImageFile && !contractImageFileUrl) {
      setContractFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls: any =
      licenseImageFileUrl &&
      licenseImageFileUrl?.length > 0 &&
      (contractImageFileUrl || contractImageFileUrl?.length > 0);

    if (shouldMakeApiCalls && businessId) {
      dispatch(updateIsLoading(true));
      const updateSupplierResponse: any = await updateSupplierApiCall();
      dispatch(updateIsLoading(false));
      if (
        updateSupplierResponse &&
        updateSupplierResponse?.status === "SUCCESS"
      ) {
        const response: any = await rejectSupplier?.fetchData();
        if (response?.status === "SUCCESS") {
          dispatch(
            setToastStatusApproval({
              message: "Rejected the supplier",
              status: "rejected",
              type: "supplier",
            } as any)
          );
          navigate(routeConfig.supplierBusinessApprovalPage);
        } else {
          setToastData({
            message: response?.response?.data?.error_msg,
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
        }

        reset();
      }
    }
  };
  const approveSelectedSupplier = async () => {
    if (!licenseImageFile && !licenseImageFileUrl) {
      setLicenseFileError(true);
    }
    if (!contractImageFile && !contractImageFileUrl) {
      setContractFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls: any =
      licenseImageFileUrl &&
      licenseImageFileUrl?.length > 0 &&
      (contractImageFileUrl || contractImageFileUrl?.length > 0);

    if (shouldMakeApiCalls && businessId) {
      dispatch(updateIsLoading(true));
      const updateBuyerResponse: any = await updateSupplierApiCall();
      dispatch(updateIsLoading(false));
      if (updateBuyerResponse && updateBuyerResponse?.status === "SUCCESS") {
        dispatch(updateIsLoading(true));
        const approveResonse: any = await approveSupplier?.fetchData();
        dispatch(updateIsLoading(false));
        if (approveResonse?.status === "SUCCESS") {
          dispatch(
            setToastStatusApproval({
              message: "Approved the supplier",
              status: "approved",
              type: "supplier",
            } as any)
          );
          navigate(routeConfig.supplierBusinessApprovalPage);
        } else {
          setToastData({
            message: approveResonse?.response?.data?.error_msg,
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 5000);
        }
        reset();
      }
    }
  };

  const new_business = {
    business_name: values?.businessName,
    email_id: values?.emailID,
    license_number: values?.licenseNo,
    license_file: licenseImageFileUrl && licenseImageFileUrl,
    vat_number: values?.vatNo,
    vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
    account_name: values?.accountName ?? "",
    contract_file: contractImageFileUrl && contractImageFileUrl,
    ware_houses: [
      {
        phone_number: `${(values?.countryName as any)?.dial_codes}#${values?.phNo
          }`,
        contact_name: values?.contactName,
        is_default: true,
        saved_as: values?.businessName,
        address: values?.address,
        area: values?.area,
        emirate: {
          id: (values?.emirates as any)?.id,
        },
        country: {
          iso_2: (values?.country as any)?.iso_2,
        },
      },
    ],
    min_order_value: values?.minimumOrderValue,
    payment_options:
      values?.paymentMethod === "both"
        ? ["cod", "online"]
        : [values?.paymentMethod],
    deliverable_locations: deliveryLocations,
    logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
    iban: values?.iban ? values?.iban : "",
  };

  // const business_setup =
  //   existingSuppliersFetchedList && existingSuppliersFetchedList.length > 0
  //     ? { existing_business: existing_business }
  //     : { new_business: new_business };

  const postFinalSupplierSetupData = useAxios({
    axiosParams: {
      url: "admin/supplier",
      data: {
        email: values?.userEmailID,
        password: values?.password,
        first_name: values?.firstName,
        last_name: values?.lastName,
        customer_type: "company",
        role: {
          role_key: (values?.userType as any)?.payloadValue,
        },
        business: new_business,
      },
    },
    method: "POST",
  });
  const [loggedInUserDetails] = useState<any>(
    JSON.parse(localStorage.getItem("loggedInUser") || "{}")
  );

  const postSupplierCustomerForm = useAxios({
    axiosParams: {
      url: `admin/supplier/${businessId}/customer/${customerId}`,
      data: {
        first_name: values?.firstName,
        last_name: values?.lastName,
        role: (values?.userType as any)?.payloadValue,
        updated_customer_id: loggedInUserDetails && loggedInUserDetails?.id,
      },
    },
    method: "PUT",
  });

  // update the current business setup
  const updateCurrentBusinessSetup = useAxios({
    axiosParams: {
      url: `${getCurrentProductStatus === "approved" ? "admin" : "store"
        }/supplier/${businessId && businessId}`,
      data: {
        business_name: values?.businessName,
        license_number: values?.licenseNo,
        license_file: licenseImageFileUrl && licenseImageFileUrl,
        vat_number: values?.vatNo,
        vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
        logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
        contract_file: contractImageFileUrl && contractImageFileUrl,
        min_order_value: +values?.minimumOrderValue,
        iban: values?.iban ?? "",
        deliverable_locations: deliveryLocations,
        // status: approvalStatus
        //   ? "approved"
        //   : supplierEdit
        //   ? "approved"
        //   : "review",
        payment_options:
          values?.paymentMethod === "both"
            ? ["cod", "online"]
            : [values?.paymentMethod],
        account_name: values?.accountName ?? "",
        ware_houses: [
          {
            id: businessStoreId && businessStoreId,
            address: values?.address,
            area: values?.area,
            emirate: {
              id: selectedEmirate?.id,
            },
            country: {
              iso_2: selectedCountry?.iso_2,
            },
            contact_name: values?.contactName,
            phone_number: `${(values?.countryName as any)?.dial_codes}#${values?.phNo
              }`,
          },
        ],
        super_admin: {
          first_name: values?.firstName,
          last_name: values?.lastName,
          email: values?.userEmailID
        },
        email_id: values?.emailID,

      },
      // data: {
      //   is_edit: "admin",
      //   business_id: userId,
      //   ware_house_id: userId || businessId,
      //   business_name: values?.businessName,
      //   contact_name: values?.contactName,
      //   email_id: values?.emailID,
      //   first_name: values?.firstName,
      //   last_name: values?.lastName,
      //   phone_number: `${(values?.countryName as any)?.dial_codes}#${
      //     values?.phNo
      //   }`,
      //   password: values?.password,
      //   logo_file: logoImageFileUrl !== "" ? logoImageFileUrl : null,
      //   license_number: values?.licenseNo,
      //   license_file: licenseImageFileUrl && licenseImageFileUrl,
      //   contract_file: contractImageFileUrl && contractImageFileUrl,
      //   min_order_value: values?.minimumOrderValue,
      //   iban: values?.iban ?? "",
      //   vat_number: values?.vatNo,
      //   vat_file: vatImageFileUrl !== "" ? vatImageFileUrl : null,
      //   deliverable_locations: deliveryLocations,
      //   status: approvalStatus
      //     ? "approved"
      //     : supplierEdit
      //     ? "approved"
      //     : "review",
      //   saved_addresses: {
      //     address: values?.address,
      //     area: values?.area,
      //     emirate: {
      //       id: selectedEmirate?.id,
      //     },
      //     country: {
      //       iso_2: selectedCountry?.iso_2,
      //     },
      //   },
      //   payment_options:
      //     values?.paymentMethod === "both"
      //       ? ["cod", "online"]
      //       : [values?.paymentMethod],
      //   account_name: values?.accountName ?? "",
      // },
    },
    method: "PUT",
  });
  const submit = async (data: any) => {
    if (!licenseImageFile && !licenseImageFileUrl) {
      setLicenseFileError(true);
    }
    if (!contractImageFile && !contractImageFileUrl) {
      setContractFileError(true);
    }
    if (!vatImageFile && !vatImageFileUrl && vatImageFileUrl?.length === 0) {
      setVatFileError(false);
    }
    const shouldMakeApiCalls: any =
      licenseImageFileUrl &&
      licenseImageFileUrl?.length > 0 &&
      (contractImageFileUrl || contractImageFileUrl?.length > 0);

    if (shouldMakeApiCalls && !userId && !businessId) {
      dispatch(updateIsLoading(true));
      console.log(
        isContractFileChanged,
        isTradeLicenceFileChanged,
        isVatFileChanged,
        "logg"
      );
      const response: any = await postFinalSupplierSetupData?.fetchData();
      dispatch(updateIsLoading(false));
      if (response?.status === "SUCCESS") {
        navigate(routeConfig.adminAddBuyer);
        reset();
        dispatch(
          setToastStatusApproval({
            message: "Successfully created the supplier",
            status: "created",
            type: "supplier",
          } as any)
        );
      } else {
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        // reset(); if the form needs to be reset
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      }
    } else if (shouldMakeApiCalls) {
      if (!supplierEdit && getCurrentProductStatus === "review") {
        await approveSelectedSupplier();
      } else {
        dispatch(updateIsLoading(true));
        console.log("here i am ")
        const response: any = await updateSupplierApiCall(data);
        if (response && response?.status === "SUCCESS") {
          navigate(-1);
        }
        dispatch(updateIsLoading(false));
      }
      if (editForm) {
        dispatch(
          setToastStatusApproval({
            message: "Successfully edited the supplier",
            status: "edited",
            type: "supplier",
          } as any)
        );
      }
    }
  };

  const submitSupplierCustomerForm = async (data: any) => {
    dispatch(updateIsLoading(true));

    const response: any = await postSupplierCustomerForm.fetchData();

    dispatch(updateIsLoading(false));
    if (response?.status === "SUCCESS") {
      navigate(routeConfig.adminAddBuyer);
      reset();
      dispatch(
        setToastStatusApproval({
          message: "Successfully updated customer details",
          status: "created",
          type: "buyer",
        } as any)
      );
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      // reset(); if the form needs to be reset
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const handleBulkUpload = () => {
    navigate(
      routeConfig.uploadSupplierProductsFromAdmin.replace(
        ":supplierId",
        editSupplierData?.id
      )
    );
  };

  const handleLinkProduct = () => {
    if (
      userId &&
      editSupplierData &&
      editSupplierData?.id &&
      (editSupplierData?.customer_id || editSupplierData?.modified_customer_id)
    ) {
      navigate(routeConfig.linkProduct, {
        state: {
          supplierId: editSupplierData?.id,
          linkProduct: true,
        },
      });
    } else {
      setToastData({
        message: "Customer ID is not available",
        status: "FAIL",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setRolesOptions(constants.rolesOptions);
  };
  const handleRolesSubmit = () => {
    let selectedRoles = rolesOptions.filter((role) => role.checked);
    handleClose();
    setRolesOptions(constants.rolesOptions);
  };
  const onCancel = () => {
    handleClose();
    setRolesOptions(constants.rolesOptions);
  };

  const updateAndApprove = () => {
    setApprovalStatus(true);
  };

  useEffect(() => {
    if (approvalStatus) {
      approveSelectedSupplier();
    }
  }, [approvalStatus]);

  const checkEmail = useAxios({
    axiosParams: {
      url: "store/customer/check-email",
      data: {
        email: watch("userEmailID"),
      },
    },
    method: "POST",
  });

  const checkEmailIsExist = async () => {
    const response: any = await checkEmail.fetchData();
    if (response && response?.status === 1004) {
    } else {
      setToastData({
        message: "Email id already exits!",
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const isEditForm = Boolean((userId || businessId) && !editForm);
  let isUserTypeDisabled = true;

  if (editForm) {
    isUserTypeDisabled = true;
  }
  if (selectedSuggestedItem?.address) {
    isUserTypeDisabled = false;
  }
  if (isSupplierCustomer && editForm) {
    isUserTypeDisabled = false;
  }
  return (
    <div className={styles.businessSetupForm}>
      <div className={`${styles.greetings} ${styles.overrideGeetings}`}></div>
      <div className={styles.right}>
        {/* {state ? (
          
          <div className={styles.adminEditSupplierHeader}>
            <div className={styles.breadCrumbContainer}>
              <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className={styles.actionsContainer}>
              <Button
                handleClick={handleOpen}
                label="Assign Roles"
                width="120px"
                height="40px"
                secondary={true}
              />
              <Button
                height="40px"
                width="120px"
                label={"Link Product"}
                handleClick={handleLinkProduct}
              />
            </div>
          </div>
        ) : ( */}
        <div className={styles.top}>
          <div className={styles.greetings}>
            <div className={styles.user}>
              {userId || businessId ? "Edit" : "Add"} Supplier
            </div>
            {/* <div className={styles.welcome}>
                {constants.BusinessSetupForm.welcome}
              </div>
              <div className={styles.welcome}>
                {constants.BusinessSetupForm.supplierWelcomeContent}
              </div> */}
          </div>
          <div className={styles.actionsOnUser}>
            {/* {userId && (
                <Button
                  handleClick={handleOpen}
                  label="Assign Roles"
                  width="180px"
                  height="40px"
                  secondary={true}
                />
              )} */}
            {userId &&
              !isSupplierCustomer &&
              getCurrentProductStatus === "approved" && (
                <>
                  <Button
                    label={"Bulk Upload"}
                    handleClick={handleBulkUpload}
                    width="180px"
                    height="40px"
                  />
                  <Button
                    label={"Link Product"}
                    handleClick={handleLinkProduct}
                    width="180px"
                    height="40px"
                    customClass={styles.submit}
                  />
                </>
              )}
            {isEditForm && (
              <button
                className={styles.submit}
                type="button"
                onClick={() => setEditForm(!editForm)}
              >
                Edit Form
              </button>
            )}
          </div>
        </div>
        {/* )} */}
        <form
          className={styles.bottom}
          onSubmit={
            isSupplierCustomer
              ? handleSubmit(submitSupplierCustomerForm)
              : handleSubmit(submit)
          }
        >
          <div className={styles.form}>
            <div className={styles.businessSetup}>
              <div className={styles.setup}>
                {constants.BusinessSetupForm.businessSetup}
              </div>
              <div className={styles.content}>
                {constants.BusinessSetupForm.setupContent}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="Enter first name"
                    id="firstName"
                    disabled={isEditForm}
                    maxWidth="100%"
                    label={"First name"}
                    onChange={onChangeField("firstName")}
                    register={register("firstName")}
                    onFocus={() => setInputFocused(true)}
                    errorMessage={errors.firstName?.message}
                    value={watch("firstName")}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="Enter last name"
                    id="lastName"
                    disabled={isEditForm}
                    maxWidth="100%"
                    label={"Last name"}
                    onChange={onChangeField("lastName")}
                    register={register("lastName")}
                    onFocus={() => setInputFocused(true)}
                    errorMessage={errors.lastName?.message}
                    value={watch("lastName")}
                  />
                </div>
                <div
                  className={`${styles.inputField} ${styles.productNameSugg}`}
                >
                  {/* <div className={styles.label}>Business name</div> */}
                  <InputField
                    placeholder="Enter business name"
                    id="businessName"
                    maxWidth="100%"
                    label={"Business name"}
                    onChange={onChangeField("businessName")}
                    register={register("businessName")}
                    onFocus={() => setInputFocused(true)}
                    errorMessage={errors.businessName?.message}
                    disabled={isEditForm || isSupplierCustomer}
                    value={watch("businessName")}
                  />

                  {/* {inputFocused &&
                      existingSuppliersFetchedList &&
                      existingSuppliersFetchedList.length > 0 && (
                        <div className={styles.productNameSuggestionsContainer}>
                          {existingSuppliersFetchedList &&
                            existingSuppliersFetchedList?.map(
                              (item: any, index: number) => (
                                <div
                                  key={item.productId}
                                  onClick={() => {
                                    handleSuggestion(item);
                                  }}
                                  className={`${styles.productSuggestionItem} ${
                                    index !==
                                    existingSuppliersFetchedList.length - 1
                                      ? styles.seperator
                                      : null
                                  }`}
                                >
                                  {item?.business_name}
                                </div>
                              )
                            )}
                        </div>
                      )} */}
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("contactName")}
                    minWidth="400px"
                    id="contactName"
                    label="Primary contact name"
                    placeholder="Enter name here"
                    onChange={onChangeField("contactName")}
                    register={register("contactName")}
                    errorMessage={errors.contactName?.message}
                    disabled={isEditForm || isSupplierCustomer}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    disabled={isEditForm || isSupplierCustomer}
                    maxWidth="100%"
                    id="emailID"
                    label="Business Email ID"
                    placeholder="Enter email here"
                    onChange={onChangeField("emailID")}
                    register={register("emailID")}
                    errorMessage={errors.emailID?.message}
                    value={watch("emailID")}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    checkEmail={true}
                    checkEmailIsExist={checkEmailIsExist}
                    value={watch("userEmailID")}
                    id="userEmailID"
                    label="User Email ID"
                    placeholder="Enter user email here"
                    onChange={onChangeField("userEmailID")}
                    register={register("userEmailID")}
                    errorMessage={errors.userEmailID?.message}
                    disabled={isEditForm || isSupplierCustomer}
                  />
                </div>

                {!userId && !businessId && (
                  <div className={styles.inputField}>
                    <InputField
                      type={"password"}
                      id="password"
                      label="Password"
                      placeholder="Enter password here"
                      onChange={onChangeField("password")}
                      register={register("password")}
                      errorMessage={errors.password?.message}
                      value={watch("password")}
                    />
                  </div>
                )}
                {!userId && !businessId && (
                  <div className={styles.inputField}>
                    <InputField
                      type={"password"}
                      id="confirmPassword"
                      label="Confirm Password"
                      placeholder="Confirm Password"
                      onChange={onChangeField("confirmPassword")}
                      register={register("confirmPassword")}
                      errorMessage={errors.confirmPassword?.message}
                      value={watch("confirmPassword")}
                    />
                  </div>
                )}
                <div className={styles.inputField}>
                  <Dropdown
                    customDropdownStyles={styles.dropDownMargin}
                    id={"userType"}
                    label="User Type"
                    editForm={isUserTypeDisabled}
                    disabled={editSupplierData?.customer?.is_super_user}
                    options={constants.userTypesListSupplier}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    register={register("userType")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("userType")(selectedOption);
                      setUserType(selectedOption);
                    }}
                    defaultLabel={userType?.name ?? "Select user type .."}
                    selectedValue={userType}
                    defaultValue={userType}
                    errorMessage={errors.userType?.message as any}
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={mobileNumberCode}
                    width="100%"
                    overlayWidth={"300px"}
                    label="Phone number"
                    id={"phnNo"}
                    options={countriesList}
                    registerMobileNumber={register("phNo")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      if (selectedOption) {
                        onChangeField("countryName")(selectedOption);
                        setMobileNumberCode(selectedOption);
                      }
                    }}
                    errorMessage={errors.phNo?.message}
                    mobileNumberField={true}
                    multiple={false}
                    editForm={isEditForm || isSupplierCustomer}
                  />
                </div>
                <div className={styles.inputField}>
                  <label className={styles.label}>
                    {constants.BusinessSetupForm.logoLabel}
                  </label>
                  <div className={styles.uploadContainer}>
                    <FileUploadCard
                      editForm={isEditForm || isSupplierCustomer}
                      width={"100%"}
                      id={1}
                      accept=".jpg, .jpeg, .png,"
                      register={register("logo")}
                      onChange={onChangeField("logo")}
                      file={logoImageFile}
                      imageUrl={logoImageFileUrl}
                      setImageUrl={setLogoImageFileUrl}
                      setFile={setLogoImageFile}
                      isMultipleFiles={false}
                    />
                  </div>
                </div>
                <div className={styles.inputField}>
                  <label
                    className={`${styles.label} ${contractFileError ? styles.fieldError : ""
                      }`}
                  >
                    {constants.BusinessSetupForm.signedContractLabel}
                  </label>
                  <div className={styles.uploadContainer}>
                    <FileUploadCard
                      width={"100%"}
                      id={3}
                      accept=".jpg, .jpeg, .png, .pdf"
                      register={register("contract")}
                      error={contractFileError && "Upload contract file"}
                      onChange={onChangeField("contract")}
                      file={contractImageFile}
                      imageUrl={contractImageFileUrl}
                      setImageUrl={setContractImageFileUrl}
                      setFile={setContractImageFile}
                      editForm={isEditForm || isSupplierCustomer}
                    />
                  </div>
                </div>

                <div className={styles.uploadFields}>
                  <div
                    className={`${styles.inputField} ${styles.marginBottom}`}
                  >
                    <InputField
                      maxWidth="100%"
                      minWidth="400px"
                      id="licenseNo"
                      label="Trade license number"
                      placeholder="Enter license number"
                      onChange={onChangeField("licenseNo")}
                      register={register("licenseNo")}
                      value={watch("licenseNo")}
                      errorMessage={
                        errors.licenseNo?.message &&
                          errors.licenseNo?.message?.length > 60
                          ? `${errors.licenseNo?.message?.slice(0, 60)}...`
                          : errors.licenseNo?.message
                      }
                      disabled={isEditForm || isSupplierCustomer}
                    />
                    <div className={styles.uploadWithInput}>
                      <FileUploadCard
                        editForm={isEditForm || isSupplierCustomer}
                        width={"100%"}
                        id={2}
                        accept=".jpg, .jpeg, .png, .pdf"
                        register={register("licenseFile")}
                        error={licenseFileError && "Upload license file"}
                        onChange={onChangeField("licenseFile")}
                        file={licenseImageFile}
                        imageUrl={licenseImageFileUrl}
                        setImageUrl={setLicenseImageFileUrl}
                        setFile={setLicenseImageFile}
                      />
                    </div>
                  </div>

                  <div
                    className={`${styles.inputField} ${styles.marginBottom}`}
                  >
                    <InputField
                      minWidth="400px"
                      id="licenseNo"
                      label="VAT number (optional)"
                      placeholder="Enter VAT number"
                      onChange={onChangeField("vatNo")}
                      register={register("vatNo")}
                      value={watch("vatNo")}
                      errorMessage={
                        errors.vatNo?.message &&
                          errors.vatNo?.message?.length > 60
                          ? `${errors.vatNo?.message?.slice(0, 60)}...`
                          : errors.vatNo?.message
                      }
                      disabled={isEditForm || isSupplierCustomer}
                    />
                    <div className={styles.uploadWithInput}>
                      <FileUploadCard
                        editForm={Boolean(isEditForm) || isSupplierCustomer}
                        width={"100%"}
                        id={4}
                        accept=".jpg, .jpeg, .png, .pdf"
                        register={register("vatFile")}
                        onChange={onChangeField("vatFile")}
                        file={vatImageFile}
                        error={vatFileError && "Upload vat file"}
                        setFile={setVATImageFile}
                        imageUrl={vatImageFileUrl}
                        setImageUrl={setVATImageFileUrl}
                      />
                    </div>
                  </div>
                </div>
                <div className={`${styles.inputField} ${styles.marginBottom}`}>
                  <InputField
                    maxWidth="100%"
                    value={watch("minimumOrderValue")}
                    minWidth="400px"
                    id="minimumOrderValue"
                    label="Minimum order value"
                    placeholder="Minimum order value"
                    onChange={onChangeField("minimumOrderValue")}
                    disabled={isEditForm || isSupplierCustomer}
                    register={register("minimumOrderValue")}
                    errorMessage={
                      errors.minimumOrderValue?.message &&
                        errors.minimumOrderValue?.message?.length > 65
                        ? `${errors.minimumOrderValue?.message?.slice(
                          0,
                          65
                        )}...`
                        : errors.minimumOrderValue?.message
                    }
                    type="number"
                  />
                </div>

                {/* {isStaffUser && (
                    <div className={styles.inputField}>
                      <InputField
                        type="number"
                        id="maxOrderLimit"
                        label="Max order limit"
                        placeholder="Enter max order limit"
                        onChange={onChangeField("maxOrderLimit")}
                        register={register("maxOrderLimit")}
                        errorMessage={errors.maxOrderLimit?.message}
                        disabled={userId && !editForm}
                      />
                    </div>
                  )} */}
                <div>
                  <div className={`${styles.typeOfPayment}`}>
                    <div className={styles.mainLabel}>
                      {constants.BusinessSetupForm.paymentLabel}
                    </div>
                    <div className={`${styles.radioBoxContainer}`}>
                      <Controller
                        name="paymentMethod"
                        control={control}
                        defaultValue="cod"
                        rules={{ required: "Please select a payment method" }}
                        render={({ field }) => (
                          <>
                            <div
                              className={styles.radioBox}
                              style={{
                                pointerEvents: `${isEditForm || isSupplierCustomer

                                  ? "none"
                                  : "auto"

                                  }`,
                              }}
                            >
                              <RadioButton
                                checked={field.value === "cod"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("cod");
                                }}
                                value="cod"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="cod"
                                onClick={() => {
                                  field.onChange("cod");
                                  handlePaymentMethodChange("cod");
                                }}
                              >
                                {constants.BusinessSetupForm.cod}
                              </label>
                            </div>
                            <div
                              className={styles.radioBox}
                              style={{
                                pointerEvents: `${isEditForm || isSupplierCustomer

                                  ? "none"
                                  : "auto"
                                  }`,
                              }}
                            >
                              <RadioButton
                                checked={field.value === "online"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("online");
                                }}
                                value="online"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="online"
                                onClick={() => {
                                  field.onChange("online");
                                  handlePaymentMethodChange("online");
                                }}
                              >
                                {constants.BusinessSetupForm.onlinePayment}
                              </label>
                            </div>
                            <div
                              className={styles.radioBox}
                              style={{
                                pointerEvents: `${isEditForm || isSupplierCustomer

                                  ? "none"
                                  : "auto"
                                  }`,
                              }}
                            >
                              <RadioButton
                                checked={field.value === "both"}
                                handleChange={(e) => {
                                  field.onChange(e.target.value);
                                  handlePaymentMethodChange("both");
                                }}
                                value="both"
                                name="paymentMethod"
                              />
                              <label
                                htmlFor="both"
                                onClick={() => {
                                  field.onChange("both");
                                  handlePaymentMethodChange("both");
                                }}
                              >
                                {constants.BusinessSetupForm.onlineAndCod}
                              </label>
                            </div>
                          </>
                        )}
                      />
                      {errors.paymentMethod && (
                        <p className="error">{errors.paymentMethod.message}</p>
                      )}
                    </div>
                  </div>
                  <div className={`${styles.inputField} ${styles.verifyBox}`}>
                    <InputField
                      label="Provide your IBAN"
                      placeholder="Enter here..."
                      onChange={onChangeField("iban")}
                      register={register("iban")}
                      errorMessage={errors.iban?.message}
                      disabled={ibanInputDisabled || isSupplierCustomer}
                      value={watch("iban")}
                    />
                  </div>
                  {values.paymentMethod !== "cod" && (
                    <div
                      className={`${styles.inputField} ${styles.deliveryAddress}`}
                    >
                      <InputField
                        minWidth="400px"
                        id="accountName"
                        label="Account name"
                        placeholder="Enter account name here"
                        onChange={onChangeField("accountName")}
                        register={register("accountName")}
                        disabled={ibanInputDisabled || isSupplierCustomer}
                        value={watch("accountName")}
                        errorMessage={errors.accountName?.message}
                      />
                    </div>
                  )}
                </div>
                {selectedSuggestedItem && selectedSuggestedItem?.outletList && (
                  <div className={`${styles.inputField} ${styles.checkInput}`}>
                    <CheckboxButton
                      handleChange={onChangeField("chooseOutlet")}
                      register={register("chooseOutlet")}
                    // disabled={!editForm}
                    />
                  </div>
                )}
                <div className={`${styles.inputField} `}>
                  {showOutletDropdown && (
                    <Dropdown
                      customDropdownStyles={styles.dropDownMargin}
                      id={"Outlet"}
                      label="Add Outlet"
                      options={
                        selectedSuggestedItem &&
                        selectedSuggestedItem?.outletList
                      }
                      minWidth="400px"
                      isDropdownOutlineRequired={true}
                      register={register("outlet")}
                      onChange={(selectedOption: any) => {
                        setSelectedOutlet(selectedOption);
                        onChangeField("outlet")(selectedOption);
                        setValue("address", selectedOption?.address);
                        setValue("area", selectedOption?.area);
                        setValue("emirates", selectedOption?.emirates);
                        setValue("country", selectedOption?.country);
                      }}
                      errorMessage={errors.outlet?.message as any}
                      defaultLabel={selectedOutlet?.name ?? "Select Outlet"}
                      selectedValue={selectedOutlet}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.businessSetup}>
              <div className={styles.setup}>
                {constants.BusinessSetupForm.address}
              </div>
              <div className={styles.content}>
                {constants.BusinessSetupForm.adressContent}
              </div>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    editForm={isOutletChoosed}
                    maxWidth="100%"
                    minWidth="400px"
                    label="Address"
                    placeholder="Enter address"
                    onChange={onChangeField("address")}
                    register={register("address")}
                    value={watch("address")}
                    errorMessage={errors.address?.message}
                    disabled={isEditForm || isSupplierCustomer}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    editForm={isOutletChoosed}
                    maxWidth="100%"
                    minWidth="400px"
                    label="Area"
                    placeholder="Enter area here"
                    onChange={onChangeField("area")}
                    register={register("area")}
                    value={watch("area")}
                    errorMessage={errors.area?.message}
                    disabled={isEditForm || isSupplierCustomer}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"country"}
                    label="Country"
                    options={
                      countriesList &&
                      countriesList.filter(
                        (country: ICountry) => country?.iso_2 === "ae"
                      )
                    }
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("country")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("country")(selectedOption);
                      setSelectedCountry(selectedOption);
                      handleCountryClick(selectedOption);
                    }}
                    defaultLabel={selectedCountry?.name ?? "Select country"}
                    errorMessage={errors.country?.message}
                    selectedValue={
                      selectedOutlet ? selectedOutlet : selectedCountry
                    }
                    editForm={isEditForm || isSupplierCustomer}
                  />
                </div>
                <div className={styles.inputField}>
                  <Dropdown
                    id={"Emirate"}
                    label="Emirate"
                    options={emiratesList}
                    editForm={isEditForm || isSupplierCustomer}
                    minWidth="100%"
                    isDropdownOutlineRequired={true}
                    register={register("emirates")}
                    onChange={(selectedOption: ICountry) => {
                      onChangeField("emirates")(selectedOption);
                      setSelectedEmirate(selectedOption);
                    }}
                    defaultLabel={selectedEmirate?.name ?? "Select emirate"}
                    errorMessage={errors.emirates?.message}
                    selectedValue={
                      selectedOutlet
                        ? selectedOutlet?.emirates
                        : selectedEmirate
                    }
                  />
                </div>
                <div
                  className={`${styles.inputField} ${styles.verifyBox} ${styles.deliveryAddress}`}
                >
                  <Dropdown
                    id={"deliveryAddress"}
                    label="Deliverable locations"
                    options={
                      emiratesList &&
                      emiratesList.length > 0 &&
                      Array.from(
                        new Set(emiratesList.map((emirate) => emirate.value))
                      ).map((value) => {
                        return emiratesList.find(
                          (emirate) => emirate.value === value
                        );
                      })
                    }
                    editForm={isEditForm || isSupplierCustomer}
                    selectedValue={selectedLocations}
                    minWidth="400px"
                    isDropdownOutlineRequired={true}
                    register={register("deliveryAddress")}
                    onChange={onChangeField("deliveryAddress")}
                    errorMessage={errors.deliveryAddress?.message}
                    multiple={true}
                    defaultLabel="Enter delivery address here"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            {!supplierEdit &&
              businessId &&
              editSupplierData?.status === "review" ? (
              <>
                <button
                  className={styles.skip}
                  onClick={() =>
                    window?.location?.pathname.includes(
                      "/admin/supplier-approval-form/"
                    )
                      ? navigate(routeConfig.supplierBusinessApprovalPage)
                      : navigate(routeConfig.adminAddBuyer)
                  }
                >
                  Cancel
                </button>
                <button
                  style={{ marginLeft: "30px" }}
                  className={styles.skip}
                  onClick={handleSubmit(rejectSelectedSupplier)}
                >
                  Reject
                </button>
                <button
                  style={{ marginLeft: "30px" }}
                  className={styles.submit}
                  type="button"
                  onClick={handleSubmit(approveSelectedSupplier)}
                >
                  Approve
                </button>
              </>
            ) : (
              <>
                <button
                  className={styles.skip}
                  onClick={() =>
                    window?.location?.pathname.includes(
                      "/admin/supplier-approval-form/"
                    )
                      ? navigate(routeConfig.supplierBusinessApprovalPage)
                      : navigate(routeConfig.adminAddBuyer)
                  }
                >
                  Cancel
                </button>
                <button
                  className={styles.submit}
                // onClick={handleSubmit(submit)}
                >
                  {window?.location?.pathname.includes(
                    "/admin/supplier-approval-form/"
                  ) || editSupplierData?.status
                    ? "Update"
                    : constants.BusinessSetupForm.submitButton}
                </button>
                {businessId && editSupplierData.status === "rejected" && (
                  <button
                    className={styles.submit}
                    onClick={handleSubmit(updateAndApprove)}
                  >
                    {" "}
                    Update & Approve
                  </button>
                )}
              </>
            )}
          </div>
          {/* <div className={styles.action}>
            <button
              className={styles.skip}
              onClick={() => navigate(routeConfig.supplierDashboard)}
            >
              {constants.BusinessSetupForm.cancelButton}
            </button>
            <button
              className={styles.submit}
              type="submit"
              onClick={handleSubmit(submit)}
            >
              {constants.BusinessSetupForm.submitButton}
            </button>
          </div> */}
        </form>
        {toastData?.message ? (
          <ToastNotification
            icon={toastData.status === "Success" ? successToast : errorToast}
            content={toastData?.message}
            position={"top-right"}
            autoClose={5000}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal open={open} onClose={handleClose} className={styles.ratingModal}>
        <div className={styles.modalFullContainer}>
          <div className={styles.heading}>
            <div className={styles.leftContainer}>
              {constants.assignRoleHeader}
            </div>
            <div className={styles.rightContainer}>
              <img src={cancelAction} alt="" onClick={handleClose} />
            </div>
          </div>
          <div className={styles.seperator}></div>
          <div className={styles.modalBody}>
            <div className={styles.body}>
              <div className={styles.ratingContainer}></div>
              <div className={styles.questionContainer}>
                <div className={styles.options}>
                  {rolesOptions?.map((item, index) => (
                    <div className={styles.option} key={index}>
                      <CheckboxButton
                        checked={item.checked}
                        handleChange={() => {
                          setRolesOptions(
                            rolesOptions?.map((item, i) =>
                              i === index
                                ? { ...item, checked: !item.checked }
                                : item
                            )
                          );
                        }}
                      />
                      <label
                        className={styles.label}
                        onClick={() => {
                          setRolesOptions(
                            rolesOptions?.map((item, i) =>
                              i === index
                                ? { ...item, checked: !item.checked }
                                : item
                            )
                          );
                        }}
                      >
                        {item.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              label="Submit"
              handleClick={handleRolesSubmit}
              width="100px"
              customClass={styles.button}
            />
            <Button
              label="Cancel"
              handleClick={onCancel}
              width="100px"
              customClass={styles.button}
              secondary={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSupplierBusinessSetupForm;
