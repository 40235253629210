/* eslint-disable @typescript-eslint/no-unused-vars */

import styles from "./CustomerListCardStyles.module.scss";
import { useState } from "react";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods } from "../../../../../common/constants/urlConstants";
import { useDispatch } from "react-redux";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import Button from "../../../../../common/components/button/Button";
import { constants } from "../../../../../common/constants/constants";
import { ToastNotification } from "../../../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../../../assets/images/errorToast.svg";
import successToast from "../../../../../assets/images/successToast.svg";

export const CustomerListCard = (props: any) => {
  const {
    email,
    firstName,
    lastName,
    id,
    setIsDataUpdated
  } = props;
  const dispatch = useDispatch();


  const [toastData, setToastData] = useState(constants.defaultToastData);

  // Axios hook for API requests - updating the special pricing details
  const { fetchData } = useAxios({
    axiosParams: {
      url: `admin/user/verify-user-email/${id}`,
    },
    method: APIMethods.put,
  });

  //function to update the special pricing details
  const verifyEmailApi = async () => {
    try {
      dispatch(updateIsLoading(true));
      const response: any = await fetchData();
      if (response && response?.status === "SUCCESS") {
        dispatch(updateIsLoading(false));
        setToastData({
          message:
            "Email is verified.",
          status: "Success",
        });
        setIsDataUpdated(true)
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
      } else {
        console.log(response, "response?.error_msg");
        setToastData({
          message: response?.response?.data?.error_msg,
          status: "Fail",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 5000);
        dispatch(updateIsLoading(false));
      }
    } catch (e: any) {
      dispatch(updateIsLoading(false));
      setToastData({ message: "Unable to edit the price.", status: "Fail" });
    }
  };

  return (
    <>
      <div
        className={`${styles.card} `}

      >
        <div className={styles.details}>
          <div className={styles.orderID}>
            <div className={styles.icon}>

            </div>
            <div className={styles.orderDetails}>
              <div className={styles.cell}>
                {email}
              </div>
            </div>
          </div>
          <div className={styles.orderID}>

            <div className={styles.orderDetails}>
              <div className={styles.cell}>
                {firstName}
              </div>
            </div>
          </div>
          <div className={styles.orderID}>

            <div className={styles.orderDetails}>
              <div className={styles.cell}>
                {lastName}
              </div>
            </div>
          </div>



          <div
            className={`${styles.deliveryStatus}`}
            title={"Actions"}
          >
            <Button
              label={"Verify Email"}
              handleClick={verifyEmailApi}
              link
            />
          </div>
        </div>

      </div>

      {toastData?.message && (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={2000}
        />
      )}
    </>
  );
};
