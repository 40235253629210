import React, { useRef, useState } from "react";
import { Checkbox, Grid, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import styles from "./AlgoliaSearch.module.scss";
import InputField from "../../../common/components/formFields/inputField/inputField";
import readXlsxFile, { Row } from "read-excel-file";
import SampleFileButton from "./SampleFileButton/SampleFileButton";
import DownloadResults from "./DownloadResults/DownloadResults";
import ResultsTable from "./ResultsTable/ResultsTable";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { urlConstants } from "../../../common/constants/urlConstants";

export class ApiService<T> {
  public loading: boolean;
  private data: T | null;
  private error: string | null;

  constructor() {
    this.loading = false;
    this.data = null;
    this.error = null;
  }

  public async callApi({ ...config }: AxiosRequestConfig): Promise<T | null> {
    this.loading = true;
    this.data = null;
    this.error = null;

    try {
      const response: AxiosResponse<T> = await axios({
        ...config,
        url: `${urlConstants.BASE_URL}${config.url}`,
      });
      this.data = response.data;
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.message);
        this.error = error.message;
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        }
      } else {
        console.error("Unexpected error:", error);
        this.error = "Unexpected error occurred";
      }
      return null;
    } finally {
      this.loading = false;
    }
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public getData(): T | null {
    return this.data;
  }

  public getError(): string | null {
    return this.error;
  }
}

export type IAlgoliaSearchResults = {
  id: string;
  query: string;
  title: string;
  supplier: string;
  price: string;
  packaging: string;
  category: string;
  subCategory: string;
  brandName: string;
};

export type AlgoliaSearchResponse = {
  status: string;
  data: {
    [key: string]: {
      id: string;
      title: string;
      supplier: string;
      price: number[];
      packaging: string[];
      category: string;
      subCategory: string;
      brandName: string;
    }[];
  };
};

const AlgoliaSearchForm = () => {
  const [queries, setQueries] = useState(1);
  const [inputValues, setInputValues] = useState<string[]>([""]);
  const [reportData, setReportData] = useState<IAlgoliaSearchResults[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedRows, setSelectedRows] = useState<{ [key: string]: string }>(
    {}
  );
  const [loading, setLoading] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "Select",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
          <Checkbox
            key={params.row.id as string}
            checked={
              !!selectedRows[params.row.query] &&
              selectedRows[params.row.query] === params.row.id
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRows((prevSelectedRows) => ({
                  ...prevSelectedRows,
                  [params.row.query]: params.row.id as string,
                }));
              } else {
                setSelectedRows((prevSelectedRows) => {
                  const newSelectedRows = { ...prevSelectedRows };
                  delete newSelectedRows[params.row.query];
                  return newSelectedRows;
                });
              }
            }}
          />
        </div>
      ),
    },
    {
      field: "query",
      headerName: "Query",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "title",
      headerName: "Title",
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "brandName",
      headerName: "Brand Name",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "packaging",
      headerName: "Packaging",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const handleInputChange = (index: number, value: string) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);
  };

  const fetchAlgoliaData = new ApiService<AlgoliaSearchResponse>();

  const fetchAlogoliaResults = async (values: string[]) => {
    setLoading(true);
    const data = await fetchAlgoliaData.callApi({
      method: "post",
      url: "store/algolia/get-algolia-search-result",
      data: {
        queries: values.filter(Boolean),
      },
    });
    const alterdata: IAlgoliaSearchResults[] = Object.entries(data?.data || {})
      .map(([query, items]) => {
        return items.map((item) => ({
          id: item.id,
          query,
          title: item.title,
          supplier: item.supplier,
          price: item.price.map((i) => `AED ${i}`).join(", "),
          packaging: item.packaging.join(", "),
          category: item.category,
          subCategory: item.subCategory,
          brandName: item.brandName,
        }));
      })
      .flat();
    setReportData(alterdata);
    setSelectedRows({});
    setLoading(false);
  };

  const handleSubmit = async (form: React.FormEvent) => {
    form.preventDefault();
    if (inputValues.filter(Boolean).length) {
      await fetchAlogoliaResults(inputValues);
    }
  };

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const parseCSVFile = (
    file: File
  ): Promise<{ headers: string[]; values: string[][] }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = (e) => {
        const data = e.target?.result as string;
        const rows = data.split("\n").map((row) => {
          // Split by comma and handle values with commas by using regex
          const regex = /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g;
          return (
            row
              .match(regex)
              ?.map((value) =>
                value.replace(/^"|"$/g, "").replace(/""/g, '"')
              ) || []
          );
        });

        if (rows.length > 0) {
          const headers = rows[0];
          const values = rows.slice(1);
          resolve({ headers, values });
        } else {
          reject(new Error("No data found in the CSV file."));
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    let values: Row[] = [];
    const file = e.target.files?.[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileType === "application/vnd.ms-excel"
      ) {
        const rows = await readXlsxFile(file);
        values = rows.slice(1);
      } else if (fileType === "text/csv") {
        const { values: parsedValues } = await parseCSVFile(file);
        values = parsedValues;
      } else {
        console.error("Unsupported file type");
      }
    }
    setInputValues([""]);
    setQueries(1);
    await fetchAlogoliaResults(values.map((v) => v.join(" ")));
  };

  return (
    <>
      <Grid container direction="column">
        <h1 className={styles.heading}>Algolia Search Form</h1>
        <form onSubmit={handleSubmit} style={{ paddingLeft: "40px" }}>
          <Grid
            container
            direction="row"
            style={{
              width: "fit-content",
            }}
          >
            {Array.from({ length: queries }, (_, i) => (
              <Grid key={i}>
                <Grid item container className={styles.transparentInput}>
                  <InputField
                    placeholder="Search"
                    id={`Search${i}`}
                    maxWidth="100%"
                    onChange={(e: any) => handleInputChange(i, e)}
                    value={inputValues[i] || ""}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid
              container
              direction="row"
              spacing={"6px"}
              style={{
                marginTop: "10px",
              }}
            >
              <Grid item>
                <button
                  type="button"
                  className={styles.commonBtn}
                  onClick={() => {
                    if (inputValues.every(Boolean)) {
                      setQueries(queries + 1);
                      setInputValues([...inputValues, ""]);
                    }
                  }}
                >
                  Add Search
                </button>
              </Grid>
              <Grid item>
                <button
                  type="button"
                  className={styles.commonBtn}
                  onClick={() => {
                    if (queries > 1) {
                      setQueries(queries - 1);
                      setInputValues(inputValues.slice(0, -1));
                    }
                  }}
                >
                  Remove Search
                </button>
              </Grid>
              <Grid item>
                <Tooltip title="Upload a CSV or xlsx file">
                  <button
                    className={styles.commonBtn}
                    type="button"
                    onClick={handleFileUploadClick}
                    disabled={loading}
                  >
                    Upload File
                  </button>
                </Tooltip>
                <input
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  type="file"
                  className={styles.commonBtn}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={onUpload}
                />
              </Grid>
              <Grid item>
                <SampleFileButton />
              </Grid>
              <Grid item>
                <DownloadResults
                  columns={columns}
                  reportData={reportData}
                  selectedRows={selectedRows}
                  disabled={!reportData.length || loading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            style={{
              width: "fit-content",
              paddingTop: "16px",
            }}
          >
            <button
              className={styles.commonBtn}
              type="submit"
              disabled={loading}
            >
              Submit
            </button>
          </Grid>
        </form>
        <ResultsTable
          columns={columns}
          reportData={reportData}
          loading={loading}
        />
      </Grid>
    </>
  );
};

export default AlgoliaSearchForm;
