import { ComponentPropsWithRef } from "react";
import styles from "../AlgoliaSearch.module.scss";
import { GridColDef } from "@mui/x-data-grid";
import { IAlgoliaSearchResults } from "../algoliaSearch";
import { Tooltip } from "@mui/material";

type DownloadResultsProps = ComponentPropsWithRef<"button"> & {
  columns: GridColDef[];
  reportData: IAlgoliaSearchResults[];
  selectedRows: { [key: string]: string };
};

function DownloadResults({
  columns,
  reportData,
  selectedRows,
  ...props
}: DownloadResultsProps) {
  const selectedColumns = columns.filter((col) => col.field !== "select");
  const handleDownloadCSV = () => {
    const selectedReportData = reportData.filter(
      (item) => selectedRows[item.query] === item.id
    );
    const dataForDownload = [
      selectedColumns.map((col) => col.headerName),
      ...(selectedReportData.length ? selectedReportData : reportData).map(
        (item) => [
          selectedColumns
            .map((col) => {
              const value = item[col.field as keyof IAlgoliaSearchResults];
              return `"${`${value}`.replace(/"/g, '""')}"`;
            })
            .join(", "),
        ]
      ),
    ];
    const csvContent = dataForDownload.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "download.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getToolTipCopy = () => {
    if (!reportData.length) return "No results to download";
    if (Object.keys(selectedRows).length) {
      return "Download selected results";
    }
    return "Download all results";
  };

  return (
    <Tooltip title={getToolTipCopy()}>
      <button
        className={styles.commonBtn}
        type="button"
        onClick={handleDownloadCSV}
        {...props}
      >
        Download
      </button>
    </Tooltip>
  );
}

export default DownloadResults;
