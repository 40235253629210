import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import successToast from "../../../../assets/images/successToast.svg";
import errorToast from "../../../../assets/images/errorToast.svg";
import alertToast from "../../../../assets/images/alertToast.svg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import EditIcon from "../../../../assets/images/edit.svg";
// import { v4 as uuidv4 } from "uuid";
import Button from "../../../../common/components/button/Button";
import Dropdown from "../../../../common/components/dropdown/Dropdown";
import InputField from "../../../../common/components/formFields/inputField/inputField";
import { constants } from "../../../../common/constants/constants";
import {
  IEqualTo,
  IOrderingOption,
  IProductDetailsWithDiscount,
} from "../../../../models/IAddIndividualProductForm";
import { setSecondTopForm } from "../../../../reducerSlices/addIndividualProductFormSlice";

import { useAxios } from "../../../../services/useAxios";
import deleteIcon from ".././../../../assets/images/deleteIcon.svg";
import styles from "./AddIndividual.module.scss";
import { ToastNotification } from "../../../../common/components/toastNotification/ToastNotification";
// import AddAttributes from "../../../../common/components/addAttributes/AddAttrinutes";
import PopUp from "../../../../common/components/PopUp/PopUp";

import { LightTooltip } from "./AddIndividual";
import AddIndividualPartTwo from "./AddIndividualPartTwo";
// import ProductAttributes from "./ProductAttributes";
import AddAttributes from "../../../../common/components/addAttributes/AddAttributes";

const AddIndividualTwoTop = ({
  isIncludeWithVAT,
  setIsIncludeWithVAT,
  secondFormShow,
  setSecondFormShow,
  isAdmin,
  navFromProductApproval,
  selectedCertifications,
  setSelectedCertifications,
  isTheProductsData,
  isApiDataSet,
  setIsApiDataSet,
  setIsTheProductsData,
  isUpdationMode,
}: {
  isIncludeWithVAT: boolean;
  setIsIncludeWithVAT: Dispatch<SetStateAction<boolean>>;
  secondFormShow: boolean;
  setSecondFormShow: Dispatch<SetStateAction<boolean>>;
  singleProductDetails: any;
  isAdmin: boolean;
  navFromProductApproval: boolean;
  setIsTheProductsData: any;
  selectedCertifications: any;
  setSelectedCertifications: any;
  isTheProductsData: any;
  isApiDataSet: boolean;
  setIsApiDataSet: Dispatch<SetStateAction<boolean>>;
  isUpdationMode: boolean;
}) => {
  const addIndividualProductForm = useSelector(
    (state: any) => state.addIndividualProductForm
  );
  const dispatch = useDispatch();

  const [orderAmount, setOrderAmount] = useState(0);
  const [off, setOff] = useState(0);
  const [buttonLabel, setButtonLabel] = useState("Add");
  const singleProductDetails = useSelector(
    (state: any) => state.singleProductDetails
  );
  const [measuringUnits, setMeasuringUnits] = useState<IOrderingOption[]>([]);
  const [selectedMeasuringUnit, setSelectedMeasuringUnit] =
    useState<IOrderingOption>({});
  const [equalsTo, setEqualsTo] = useState<IEqualTo[]>([]);
  const [selectedEqualsTo, setSelectedEqualsTo] = useState<IEqualTo>({});
  const [tableData, setTableData] = useState<IProductDetailsWithDiscount[]>(
    addIndividualProductForm?.secondTopForm?.addedProductDetailsWithDiscount ||
    []
  );
  const [selectedAttributes, setSelectedAttributes] = React.useState<any[]>([]);
  const [allAttributesCheck, setAllAttributesCheck] =
    React.useState<any>(false);
  const { productId } = useParams();
  const [isRowEdit] = useState(false);
  const [showAddAttributeModal, setShowAddAttributeModal] = useState(false);
  const [checkAddVar, setCheckAddVar] = useState(false);
  const schema = yup.object().shape({
    variantTitle: yup.string().required("Enter variant title"),
    orderingOption: yup
      .object()
      .required("Select ordering option unit")
      .test("option-test", "Select ordering option unit", (option) =>
        Object.keys(option)?.length > 0 ? true : false
      ),
    orderingOptionValue: yup
      .number()
      .positive()
      .moreThan(0, "Enter positive number greater than 0")
      .required("Enter ordering option value")
      .typeError("Enter ordering option  value "),
    moq: yup
      .number()
      .positive()
      .moreThan(0, "Enter positive number")
      .max(9999, "Exceeded the maximum MOQ (9999)")
      .required("Enter MOQ number")
      .typeError(" Enter MOQ number")
      .test("test", "enter positive", (value) => value > 0),
    equalto: yup
      .object()
      .required("Select equal to unit option")
      .test("option-test", "Select equal to unit option", (option) =>
        Object.keys(option)?.length > 0 ? true : false
      ),
    enterNumber: yup
      .number()
      .positive()
      .moreThan(0, "Enter positive number")
      .typeError("Enter an equivalent number")
      .required("Enter number"),
    orderAmount: yup
      .number()
      .positive()
      .max(2147483647, "Exceeded the maximum order amount (2147483647)")
      .moreThan(0, "Enter amount greater than 0")
      .typeError("Enter order amount")
      // .test(
      //   "is-decimal",
      //   "Only integers is allowed ( Ex: valid - 100 , invalid - 100.2)",
      //   (value) => {
      //     console.log(Number.isInteger(value), 'Number.isInteger(value)');
      //     return Number.isInteger(value)
      //   }
      // )
      .test(
        "two-decimal-points",
        "Only two decimal places are allowed.",
        (value) => value?.toString().split(".")[1] ? value?.toString().split(".")[1].length <= 2 : true
      )
      .required("Enter order amount"),
    off: yup
      .number()
      .max(99, "offer should not exceed 99")
      .moreThan(-1, "Enter positive number")
      .typeError("Enter discount")
      .required("Enter discount"),
    discountedAmount: yup
      .number()
      .positive()
      .required("Enter discounted amount")
      .typeError("Enter discounted amount")
      .nullable(),
    // addedProductDetailsWithDiscount: yup.array(),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      off: 0,
    },
  });
  useEffect(() => {
    if (addIndividualProductForm?.secondTopForm) {
      reset(addIndividualProductForm?.secondTopForm);
    }
  }, [addIndividualProductForm, reset]);

  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      if (name === "orderAmount") {
        setOrderAmount(text);
        if (off) {
          const discountedAmount: string = calculateDiscountedAmount(
            Number(text),
            Number(off)
          );
          setValue("discountedAmount", Number(discountedAmount));
        }
      }
      if (name === "off") {
        setOff(text);
        if (orderAmount) {
          const discountedAmount: string = calculateDiscountedAmount(
            Number(orderAmount),
            Number(text)
          );
          setValue("discountedAmount", Number(discountedAmount));
        }
      }
      if (!watch("off") && !watch("orderAmount")) {
        setValue("discountedAmount", null);
      }

      if (name === "orderingOption") {
        setValue("equalto", {});
        setSelectedEqualsTo({} as any);
      }
    },
    [off, orderAmount, setValue]
  );
  useEffect(() => {
    if (orderAmount || off) {
      const discountedAmount: string = calculateDiscountedAmount(
        Number(orderAmount),
        Number(off)
      );
      setValue("discountedAmount", Number(discountedAmount));
    }
  }, [getValues().orderAmount, getValues().off]);

  // get measuring units api call starts
  const measuringUnitsApi = useAxios({
    axiosParams: {
      url: "store/units",
    },
    method: "GET",
  });

  const getMeasuringUnits = useCallback(async () => {
    const response: any = await measuringUnitsApi?.fetchData();
    if (response) {
      const updatedMeasuringUnits = response?.data?.product_units?.map(
        (unit: IEqualTo) => ({
          ...unit,
          value: unit.name,
        })
      );
      setMeasuringUnits(updatedMeasuringUnits);
    }
  }, [measuringUnitsApi]);

  useEffect(() => {
    getMeasuringUnits();
  }, []);

  const handleMeasuringUnitChange = (selectedOption: IOrderingOption) => {
    setSelectedMeasuringUnit(selectedOption);
    const updatedSubUnits = selectedOption?.sub_units?.map(
      (unit: IEqualTo) => ({
        ...unit,
        value: unit.name,
      })
    ) as IEqualTo[];
    setEqualsTo(updatedSubUnits);
  };
  // get measuring units api call ends

  const handleEqualsToChange = (selectedOption: IEqualTo) => {
    setSelectedEqualsTo(selectedOption);
  };

  const submit = () => {
    const formDataWithTableData = {
      addedProductDetailsWithDiscount: tableData,
    };

    const updatedFullData = {
      ...formDataWithTableData,
    };
    dispatch(setSecondTopForm(updatedFullData));
  };

  const values = getValues();

  const handleRemoveRow = (indexToRemove: number) => {
    const updatedTableData = tableData.filter(
      (_, index) => index !== indexToRemove
    );
    setTableData(updatedTableData);
    // setValue("addedProductDetailsWithDiscount", updatedTableData);
    const formDataWithUpdatedTableData = {
      ...addIndividualProductForm.secondBottomForm,
      addedProductDetailsWithDiscount: updatedTableData,
    };
    dispatch(setSecondTopForm(formDataWithUpdatedTableData));
    if (editRowIndex === indexToRemove) {
      setSelectedMeasuringUnit({} as any);
      setSelectedEqualsTo({} as any);
      setOff(0);
      setOrderAmount(0);
      reset();
      setButtonLabel("Add");
    } else if (indexToRemove > -1 && indexToRemove < editRowIndex) {
      const latestIndx = editRowIndex - 1;
      setEditRowIndex(latestIndx);
    }
  };
  const calculateDiscountedAmount = (orderAmount: number, off: number) => {
    const discountedAmount = (orderAmount * (100 - off)) / 100;
    const nonNegativeDiscountedAmount = Math.max(discountedAmount, 0);
    values.discountedAmount = Number(nonNegativeDiscountedAmount?.toFixed(2));
    return nonNegativeDiscountedAmount?.toFixed(2);
  };
  const [toastData, setToastData] = useState(constants.defaultToastData);
  //Validate Attributes check
  //If the attributes are mandatory below code needs to be uncommented for validation
  // const validateAttributes = (data: any) => {
  //   if (data?.[0] === null || data?.find((item: any) => !item?.typeValue)) {
  //     return false;
  //   } else if (data?.[data?.length - 1] === null) {
  //     setCheckAddVar(false);
  //     return true;
  //   } else {
  //     setCheckAddVar(false);
  //     return true;
  //   }
  // };

  const modifySelectedAtrributes = (selectedAttributes: any[]) => {
    let modifiedAttributes = [...selectedAttributes];
    const transformedArray = modifiedAttributes.map((attr: any) => {
      const { name = {}, typeValue } = attr || {};
      return {
        [name]: typeValue,
        name,
        typeValue,
        // ...rest
      };
    });
    return transformedArray;
  };

  //to remove attributes thats does't have value
  const removeValueLessAttributes = (data: any) => {
    let newData = data?.filter(
      (item: any) => item?.typeValue && item?.typeValue?.length > 0
    );
    return newData;
  };

  const handleAddRow = async (e: Event) => {
    e.preventDefault();
    const isValid = await trigger();
    // setCheckAddVar(true);
    // add function call validateAttributes(selectedAttributes) in below if conditional, if attributes are mandatory
    if (isValid) {
      const formData = getValues();
      if (formData.orderingOption && formData.moq) {
        const discountedAmount = calculateDiscountedAmount(
          formData.orderAmount,
          formData.off
        );
        const doesRowExist = tableData.some((row) => {
          return (
            row?.variantTitle === formData?.variantTitle &&
            Number(row?.discountedAmount) ===
            Number(formData?.discountedAmount) &&
            Number(row?.enterNumber) === Number(formData?.enterNumber) &&
            row?.equalto?.value === (formData?.equalto as any)?.value &&
            Number(row?.moq) === Number(formData?.moq) &&
            Number(row?.off) === Number(formData?.off) &&
            Number(row?.orderAmount) === Number(formData?.orderAmount) &&
            row?.orderingOption?.value ===
            (formData?.orderingOption as any)?.value &&
            Number(row?.orderingOptionValue) ===
            Number(formData?.orderingOptionValue) &&
            JSON.stringify(
              modifySelectedAtrributes(row?.selectedAttributes)
            ) === JSON.stringify(modifySelectedAtrributes(selectedAttributes))
          );
        });

        if (doesRowExist) {
          setToastData({
            message: "Duplicate variant found. Please provide a new one.",
            status: "Alert",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
          return;
        }
        const newTableRow = {
          variantTitle: formData.variantTitle,
          orderingOption: formData.orderingOption,
          orderingOptionValue: formData.orderingOptionValue,
          moq: formData.moq,
          equalto: formData.equalto ? formData.equalto : "",
          enterNumber: formData.enterNumber,
          orderAmount: formData.orderAmount,
          off: formData.off,
          discountedAmount: discountedAmount,
          // id: uuidv4(),
          id: null,
          selectedAttributes: removeValueLessAttributes(selectedAttributes),
        };

        setTableData(
          (prevTableData) =>
            [...prevTableData, newTableRow] as IProductDetailsWithDiscount[]
        );
        // setValue("addedProductDetailsWithDiscount", [
        //   ...(getValues("addedProductDetailsWithDiscount") || []),
        //   newTableRow,
        // ]);
        const formDataWithUpdatedTableData = {
          ...addIndividualProductForm.secondBottomForm,
          addedProductDetailsWithDiscount: [...tableData, newTableRow],
        };
        dispatch(setSecondTopForm(formDataWithUpdatedTableData));
        reset();
        setSelectedMeasuringUnit({} as any);
        setSelectedEqualsTo({} as any);
        setOff(0);
        setOrderAmount(0);
        setToastData({
          message: "Variant added successfully",
          status: "Success",
        });
        setTimeout(() => {
          setToastData(constants.defaultToastData);
        }, 3000);
        // if (tableData?.length === 5) {
        //   return;
        // }
      }
      // setAllAttributesCheck(true);
      // setCheckAddVar(false);
    }
  };

  useEffect(() => {
    dispatch(setSecondTopForm({ addedProductDetailsWithDiscount: tableData }));
  }, [tableData, dispatch]);

  const [editRow, setEditRow] = useState(false);
  const [editRowIndex, setEditRowIndex] = useState<number>(-1);
  const handleEditRow = (index: number) => {
    setEditRow(true);
    setButtonLabel("Update");
    setEditRowIndex(index);
    const rowToEdit: IProductDetailsWithDiscount = tableData[index];
    if (!rowToEdit) return;
    if (rowToEdit) {
      setValue("variantTitle", rowToEdit?.variantTitle || "");
      setValue("orderingOption", rowToEdit?.orderingOption);
      setSelectedMeasuringUnit(rowToEdit?.orderingOption);
      setValue("moq", rowToEdit?.moq);
      setValue("equalto", rowToEdit?.equalto);
      setSelectedEqualsTo(rowToEdit?.equalto);
      setValue("enterNumber", rowToEdit?.enterNumber);
      setValue("orderAmount", rowToEdit?.orderAmount);
      setValue("off", rowToEdit?.off);
      setValue("discountedAmount", rowToEdit?.discountedAmount);
      setValue("orderingOptionValue", rowToEdit?.orderingOptionValue);
      setOff(rowToEdit?.off);
      setOrderAmount(rowToEdit?.orderAmount);
      // setSelectedAttributes(rowToEdit?.selectedAttributes);
      // setIsRowEdit(true);
    }
  };

  const handleUpdateRow = async (e: Event, index: number) => {
    e.preventDefault();
    const isValid = await trigger();
    if (isValid) {
      const formData = getValues();
      if (formData.orderingOption && formData.moq) {
        const discountedAmount = calculateDiscountedAmount(
          formData.orderAmount,
          formData.off
        );
        const doesRowExist = tableData.some((row) => {
          return (
            row?.variantTitle === formData?.variantTitle &&
            Number(row?.discountedAmount) ===
            Number(formData?.discountedAmount) &&
            Number(row?.enterNumber) === Number(formData?.enterNumber) &&
            row?.equalto?.value === (formData?.equalto as any)?.value &&
            Number(row?.moq) === Number(formData?.moq) &&
            Number(row?.off) === Number(formData?.off) &&
            Number(row?.orderAmount) === Number(formData?.orderAmount) &&
            row?.orderingOption?.value ===
            (formData?.orderingOption as any)?.value &&
            Number(row?.orderingOptionValue) ===
            Number(formData?.orderingOptionValue) &&
            JSON.stringify(
              modifySelectedAtrributes(row?.selectedAttributes)
            ) === JSON.stringify(modifySelectedAtrributes(selectedAttributes))
          );
        });

        if (doesRowExist) {
          setToastData({
            message: "Details already exists",
            status: "Fail",
          });
          setTimeout(() => {
            setToastData(constants.defaultToastData);
          }, 3000);
          return;
        }
        const updatedRow: IProductDetailsWithDiscount = {
          variantTitle: formData.variantTitle,
          orderingOption: formData.orderingOption,
          orderingOptionValue: formData.orderingOptionValue,
          moq: formData.moq,
          equalto: formData.equalto ? formData.equalto : "",
          enterNumber: formData.enterNumber,
          orderAmount: formData.orderAmount,
          off: formData.off,
          discountedAmount: Number(discountedAmount),
          id: tableData[index]?.id,
          selectedAttributes: removeValueLessAttributes(selectedAttributes),
        };
        if (editRowIndex !== -1) {
          //Remove true and call function 'validateAttributes(selectedAttributes)' in below if to make attributes mandatory
          if (true) {
            const updatedTableData = [...tableData];
            updatedTableData[editRowIndex] = updatedRow;
            setTableData(updatedTableData);
            setOff(0);
            setOrderAmount(0);
            setEditRowIndex(-1);
            setSelectedEqualsTo({} as any);
            setSelectedMeasuringUnit({} as any);
            reset();
            setToastData({
              message:
                "Updated variant successfully, click on submit to save your changes",
              status: "Success",
            });
            setTimeout(() => {
              setToastData(constants.defaultToastData);
            }, 3000);
          } else {
            setCheckAddVar(true);
            return;
          }
        } else {
          setTableData((prevTableData) => [...prevTableData, updatedRow]);
          dispatch(
            setSecondTopForm({
              addedProductDetailsWithDiscount: [
                ...addIndividualProductForm?.secondTopForm
                  ?.addedProductDetailsWithDiscount,
                updatedRow,
              ],
            })
          );
          // setValue("addedProductDetailsWithDiscount", [
          //   ...(getValues("addedProductDetailsWithDiscount") || []),
          //   updatedRow,
          // ]);
        }

        reset();
        setButtonLabel("Add");
        setEditRow(false);
        setEditRowIndex(-1);
        // setIsRowEdit(false);
        // if (tableData?.length === 5) {
        //   return;
        // }
      }
    }
  };
  const getUnits = (orderUnitsId: string, equalToUnitsId: string) => {
    const orderUnitsObj = measuringUnits?.find(
      (orderUnit: any) => orderUnit?.id === orderUnitsId
    );
    const updatedSubUnits = orderUnitsObj?.sub_units?.map((sub_unit) => ({
      ...sub_unit,
      value: sub_unit?.name,
    }));
    const updatedorderUnitsObj = {
      ...orderUnitsObj,
      sub_units: updatedSubUnits,
    };
    const equalToUnitsObj = updatedorderUnitsObj?.sub_units?.find(
      (sub_unit) => sub_unit?.sub_unit_id === equalToUnitsId
    );
    return { updatedorderUnitsObj, equalToUnitsObj };
  };
  useEffect(() => {
    const attributesObject = singleProductDetails?.metadata?.attributes
      ? singleProductDetails?.metadata?.attributes
      : {};

    const attributesArray = Object.entries(attributesObject).map(
      ([key, value]) => {
        const obj = addIndividualProductForm?.firstForm?.categoriesAttributes?.find((el: any) => el.name.toLowerCase() === key.toLowerCase())
        return { ...obj, typeValue: value }

      }
    );

    setSelectedAttributes(attributesArray)
    let newArray =
      singleProductDetails &&
      singleProductDetails?.variants.map((variant: any) => {
        const { updatedorderUnitsObj, equalToUnitsObj } = getUnits(
          variant?.metadata?.orderingOptionId,
          variant?.metadata?.secondaryOrderingOptionId
        );
        const orderUnit = updatedorderUnitsObj;
        const amount = variant?.unitPrice || 0;
        const offer = variant?.metadata?.offer || 0;
        const discountedAmount = amount - amount * (offer / 100);
        const moq = variant?.minQuantity;
        const equalto = equalToUnitsObj;
        const enterNumber = variant?.metadata?.secondaryOrderingOptionValue;
        return {
          id: variant?.id,
          discountedAmount: discountedAmount,
          // orderingOption: {
          //   value: orderUnit,
          // },

          orderingOption: orderUnit,
          orderingOptionValue: variant?.metadata?.orderingOptionValue,
          orderAmount: amount,
          moq: moq,
          off: offer,
          enterNumber: enterNumber,
          // equalto: {
          //   value: equalto,
          // },
          variantTitle: variant.title,
          equalto: equalto,
          selectedAttributes: [],
        };
      });
    if (!(measuringUnits && measuringUnits?.length > 0)) {
      newArray = [];
    }
    if (newArray?.length > 0 && !isApiDataSet) {
      const updatedTableData = tableData.slice();
      newArray?.forEach((newItem: any) => {
        const existingItemIndex = updatedTableData?.findIndex(
          (item: any) => item?.id === newItem?.id
        );
        if (existingItemIndex !== -1) {
          updatedTableData[existingItemIndex] = newItem;
        } else {
          updatedTableData.push(newItem);
        }
      });
      setTableData(updatedTableData);
      setIsApiDataSet(true);
    }
  }, [singleProductDetails, measuringUnits, productId]);
  const [viewAttriutesRowIndex] = useState<any>(0);
  return (
    <div className={styles.addIndividualFormTwo}>
      <div className={styles.attributesWrapper}>
        <AddAttributes
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          attributesList={
            addIndividualProductForm &&
            addIndividualProductForm?.firstForm &&
            addIndividualProductForm?.firstForm?.categoriesAttributes
          }
          isRowEdit={isRowEdit}
          setAllAttributesCheck={setAllAttributesCheck}
          allAttributesCheck={allAttributesCheck}
        />
      </div>

      <form
        onSubmit={handleSubmit(submit)}
        key={`variant_${(
          addIndividualProductForm?.secondTopForm
            ?.addedProductDetailsWithDiscount ?? []
        )?.length
          }`}
      >
        <div className={styles.producingPricing}>
          <div className={styles.producingPricingForm}>
            <div className={styles.producingPricingText}>Product pricing</div>
            <div className={`${styles.OuterFormBox} ${styles.customPadding}`}>
              <form
                onSubmit={handleSubmit(submit)}
                className={styles.towTopForm}
              >
                <div className={styles.producingPricingTextArea}>
                  <div className={styles.orderTextArea}>
                    <div className={styles.input}>
                      <div className={styles.label}>Variant Title</div>
                      <InputField
                        placeholder="Enter Variant Title..."
                        id="variantTitle"
                        onChange={onChangeField("variantTitle")}
                        register={register("variantTitle")}
                        errorMessage={errors.variantTitle?.message ?? ""}
                        minWidth="100%"
                      />
                    </div>
                  </div>
                  <div className={styles.orderTextArea}>
                    <div className={styles.input}>
                      <div className={styles.label}>Ordering option</div>
                      <Dropdown
                        borderRightRemove={true}
                        minWidth="100%"
                        label={""}
                        options={measuringUnits}
                        defaultLabel={
                          addIndividualProductForm?.secondTopForm
                            ?.orderingOption?.name ||
                          selectedMeasuringUnit?.name ||
                          "Select ordering option..."
                        }
                        selectedValue={
                          selectedMeasuringUnit
                            ? selectedMeasuringUnit
                            : measuringUnits[0]
                        }
                        id="orderingOption"
                        register={register("orderingOption")}
                        onChange={(selectedOption: IOrderingOption) => {
                          handleMeasuringUnitChange(selectedOption);
                          onChangeField("orderingOption")(selectedOption);
                        }}
                        errorMessage={errors.orderingOption?.message}
                      />
                    </div>
                    <div className={styles.lineDivider}></div>
                    <div className={styles.input}>
                      <div className={`${styles.label} ${styles.hiddenMode}`}>
                        MOQ
                      </div>
                      <InputField
                        borderLeftRemove={true}
                        width="100%"
                        id="orderingOptionValue"
                        type="number"
                        onChange={onChangeField("orderingOptionValue")}
                        register={register("orderingOptionValue")}
                        errorMessage={errors.orderingOptionValue?.message ?? ""}
                        minWidth="100%"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.textAreaSecondary} ${styles.flexColumn}`}
                >
                  <div className={styles.discountTextArea}>
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>Equals to</div>
                        <Dropdown
                          borderRightRemove={true}
                          minWidth="100%"
                          label={""}
                          options={equalsTo || []}
                          defaultLabel={
                            addIndividualProductForm?.secondTopForm?.equalto
                              ?.name ||
                            selectedEqualsTo?.name ||
                            "Select equals to..."
                          }
                          selectedValue={
                            selectedEqualsTo
                              ? selectedEqualsTo
                              : equalsTo?.length > 0
                                ? equalsTo?.[0]
                                : [""]
                          }
                          id="equalto"
                          onChange={(selectedOption: IEqualTo) => {
                            handleEqualsToChange(selectedOption);
                            onChangeField("equalto")(selectedOption);
                          }}
                          register={register("equalto")}
                          errorMessage={errors.equalto?.message}
                        />
                      </div>
                      <div className={styles.lineDivider}></div>
                      <div className={`${styles.input}  ${styles.smallInput}`}>
                        <div className={styles.label}>&nbsp;</div>
                        <InputField
                          borderLeftRemove={true}
                          placeholder="Enter number..."
                          width="100%"
                          id="enterNumber"
                          type="number"
                          onChange={onChangeField("enterNumber")}
                          register={register("enterNumber")}
                          errorMessage={errors.enterNumber?.message ?? ""}
                          minWidth="100%"
                        />
                      </div>
                    </div>
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>Order amount</div>
                        <InputField
                          placeholder="AED Enter Amount... "
                          width="100%"
                          id="orderAmount"
                          type="number"
                          onChange={onChangeField("orderAmount")}
                          register={register("orderAmount")}
                          errorMessage={errors.orderAmount?.message ?? ""}
                          minWidth="100%"
                          maxWidth="none"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.discountTextArea} ${styles.moqPadding}`}
                  >
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>% Off</div>
                        <InputField
                          borderRightRemove={true}
                          placeholder="Enter %..."
                          width="100%"
                          id="off"
                          onChange={onChangeField("off")}
                          register={register("off")}
                          type="number"
                          errorMessage={errors.off?.message ?? ""}
                          minWidth="100%"
                        />
                      </div>
                      <div className={styles.lineDivider}></div>
                      <div className={`${styles.input} ${styles.discountText}`}>
                        <div className={styles.label}>Discounted Amount</div>
                        <InputField
                          editForm={true} // disabling this field because it's value can be calculated from order amount & off
                          borderLeftRemove={true}
                          placeholder="AED 0.00"
                          width="100%"
                          id="discountedAmount"
                          type="number"
                          onChange={onChangeField("discountedAmount")}
                          register={register("discountedAmount")}
                          errorMessage={
                            errors.discountedAmount?.message
                              ? "Enter discounted amount"
                              : ""
                          }
                          minWidth="100%"
                        />
                      </div>
                    </div>
                    <div className={styles.orderTextArea}>
                      <div className={styles.input}>
                        <div className={styles.label}>MOQ</div>
                        <InputField
                          placeholder="Enter Minimum Order Quantity..."
                          id="moq"
                          type="number"
                          onChange={onChangeField("moq")}
                          register={register("moq")}
                          errorMessage={errors.moq?.message ?? ""}
                          minWidth="100%"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.orderTextArea} ${styles.attributeContainer}`}
                  >
                    <div className={styles.attributeBox}>
                      {/* <div className={styles.label}>Add Attributes</div> */}
                      {/* <AddAttributes
                          selectedAttributes={selectedAttributes}
                          setSelectedAttributes={setSelectedAttributes}
                          attributesList={
                            addIndividualProductForm &&
                            addIndividualProductForm?.firstForm &&
                            addIndividualProductForm?.firstForm
                              ?.categoriesAttributes
                          }
                          isRowEdit={isRowEdit}
                          setAllAttributesCheck={setAllAttributesCheck}
                          allAttributesCheck={allAttributesCheck}
                        /> */}
                      {/* Uncomment the below condtion to show error msg on attributes empty */}
                      {checkAddVar && (
                        // !validateAttributes(selectedAttributes) &&
                        <p className={styles.attributeErrMsg}>
                          Please add atleast one attribute including attribute
                          value{" "}
                        </p>
                      )}
                      {/* <Dropdown
                          label={""}
                          options={attributes}
                          defaultLabel={
                            addIndividualProductForm?.secondTopForm?.addAttributes
                              ?.name ||
                            selectedAttribute?.name ||
                            "Select Attributes..."
                          }
                          selectedValue={
                            selectedAttribute
                              ? selectedAttribute
                              : attributes?.length > 0 && attributes?.[0]
                          }
                          id="addAttributes"
                          register={register("addAttributes")}
                          onChange={(selectedOption: any) => {
                            handleAttributeChange(selectedOption);
                            onChangeField("addAttributes")(selectedOption);
                          }}
                          errorMessage={errors.addAttributes?.message}
                        /> */}
                    </div>
                    <div className={styles.Addbtn}>
                      {editRow ? (
                        <Button
                          label={buttonLabel}
                          large={true}
                          handleClick={(e: Event) =>
                            handleUpdateRow(e, editRowIndex)
                          }
                        />
                      ) : (
                        <Button
                          label={buttonLabel}
                          large={true}
                          handleClick={(e: Event) => handleAddRow(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className={styles.tableContainer}>
              {tableData && tableData?.length > 0 && (
                <>
                  <div className={styles.tableHeading}>
                    <div className={styles.tableHeadData1}>Variant Title</div>
                    <div className={styles.tableHeadData1}>
                      Product ordering options
                    </div>
                    <div className={styles.tableHeadData2}>Equals to</div>
                    <div className={styles.tableHeadData3}>Order amount</div>
                    <div className={styles.tableHeadData4}>% Off</div>
                    <div className={styles.line}></div>
                    <div className={styles.tableHeadDat5}>
                      Discounted amount
                    </div>
                  </div>
                  <div className={styles.horizontal}></div>
                </>
              )}
              {tableData &&
                tableData.length > 0 &&
                tableData.map(({ ...row }, index) => (
                  <>
                    <div className={styles.tableData} key={index}>
                      <div
                        className={`${styles.tableHeadData1} ${styles.pointerCursor}`}
                      >
                        <LightTooltip title={row.variantTitle} placement="top">
                          <span className={styles.productName}>{row.variantTitle}</span>
                        </LightTooltip>
                      </div>
                      <div
                        className={`${styles.tableHeadData1} ${styles.pointerCursor}`}
                      >
                        <LightTooltip
                          title={`${row.orderingOptionValue} Min. order quantity = ${row?.moq}`}
                          placement="top"
                        >
                          {row.orderingOptionValue} (Min. order quantity ={" "}
                          {row?.moq})
                        </LightTooltip>
                      </div>
                      <div className={styles.tableHeadData2}>
                        <LightTooltip
                          title={row.orderingOptionValue}
                          placement="top"
                        >
                          <div className={styles.optionValue}>
                            {row.orderingOptionValue}
                          </div>
                        </LightTooltip>

                        <LightTooltip title={row.enterNumber} placement="top">
                          <div className={styles.optionValue}>
                            {` x ${row.enterNumber} `}
                          </div>
                        </LightTooltip>
                        <div>
                          {/* {row.equalto?.value}/{row.orderingOptionValue} */}
                          {row.equalto?.value}
                        </div>
                      </div>
                      {/* <div className={styles.tableHeadData2}>
                        {`${row.orderingOptionValue} x `}
                        {row.enterNumber} {row.equalto?.value}/
                        {row.orderingOption.value}
                      </div> */}
                      <div
                        className={`${styles.tableHeadData3} ${styles.pointerCursor}`}
                      >
                        <LightTooltip title={row?.orderAmount} placement="top">
                          AED {Number(row?.orderAmount)?.toFixed(2)}
                        </LightTooltip>
                      </div>
                      <div className={styles.tableHeadData4}>{row.off}%</div>
                      <div className={styles.line}></div>
                      <div
                        className={`${styles.tableHeadData5} ${styles.pointerCursor}`}
                      >
                        <LightTooltip
                          title={row?.discountedAmount}
                          placement="top"
                        >
                          AED {Number(row?.discountedAmount).toFixed(2)}
                        </LightTooltip>
                      </div>
                      <div className={styles.actionsContainer}>
                        <div
                          className={styles.remove}
                          onClick={() => handleEditRow(index)}
                        >
                          <div className={styles.deleteIcon}>
                            <img src={EditIcon} alt="Delete" />
                          </div>
                          <div className={styles.delete}>Edit</div>
                        </div>
                        <div
                          className={styles.remove}
                          onClick={() => handleRemoveRow(index)}
                        >
                          <div className={styles.deleteIcon}>
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                          <div className={styles.delete}>Remove</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.horizontal}></div>
                  </>
                ))}
              {showAddAttributeModal && (
                <PopUp
                  open={showAddAttributeModal}
                  setOpen={setShowAddAttributeModal}
                  handleClose={() => setShowAddAttributeModal(false)}
                  title="Selected Attributes"
                >
                  <div className={styles.attriburteViewontainer}>
                    <div className={styles.attributeViewBody}>
                      {tableData[
                        viewAttriutesRowIndex
                      ]?.selectedAttributes?.map((attribute: any) => (
                        <div className={styles.attributeView}>
                          <div className={styles.attributeName}>
                            {attribute?.name}
                          </div>
                          <div className={styles.attributeValue}>
                            {attribute?.typeValue}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </PopUp>
              )}
            </div>
          </div>
        </div>
        {toastData?.message ? (
          <ToastNotification
            icon={
              toastData.status === "Success"
                ? successToast
                : toastData.status === "Alert"
                  ? alertToast
                  : errorToast
            }
            content={toastData?.message}
            position={"top-right"}
            autoClose={50000}
          />
        ) : (
          <></>
        )}
      </form>
      <AddIndividualPartTwo
        isIncludeWithVAT={isIncludeWithVAT}
        setIsIncludeWithVAT={setIsIncludeWithVAT}
        selectedCertifications={selectedCertifications}
        setSelectedCertifications={setSelectedCertifications}
        isTheProductsData={isTheProductsData}
        setIsTheProductsData={setIsTheProductsData}
        handleAddRow={handleAddRow}
        secondFormShow={secondFormShow}
        setSecondFormShow={setSecondFormShow}
        isAdmin={isAdmin}
        navFromProductApproval={navFromProductApproval}
        isUpdationMode={isUpdationMode}
        selectedAttributes={selectedAttributes}
      />
    </div>
  );
};

export default AddIndividualTwoTop;
