/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useRef, useState } from "react";
import PageSearch from "../../../../../common/components/pageSearch/PageSearch";
import styles from "./CustomerListStyles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoading } from "../../../../../reducerSlices/loaderSlice";
import { useAxios } from "../../../../../services/useAxios";
import { APIMethods } from "../../../../../common/constants/urlConstants";
import PaginationComponent from "../../../../../common/components/pagination/Pagination";
import React from "react";
import { EmptyContainer } from "../../../../../common/pages/ordersListing/components/emptyContainer/EmptyContainer";
import { CustomerListCard } from "../CustomerListCard/CustomerListCard";

const CustomerList = ({ setIsEmpty }: any) => {
  const searchCustomStyles = {
    top: "0px",
    marginTop: "10px",
    marginBottom: "15px"
  };
  const scrollTabDataRef = useRef<HTMLDivElement>(null);

  const { loader } = useSelector((state: any) => state);
  const handleSearch = (value: string) => {
    setCurrentPage(1);
    setSearchText(value);
  };
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [customersList, setCustomersList] = useState<any>([]);
  const [customersListCount, setCustomersListCount] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [loading, setloading] = useState(false)
  const itemsPerPage = 10;
  //api to fetch the special pricing list
  const { fetchData } = useAxios({
    axiosParams: {
      url:
        `admin/user/all/list?limit=${itemsPerPage}&offset=${(currentPage - 1) * 10
        }&q=${searchText}&page=${currentPage}`
    },
    method: APIMethods.get,
  });

  const getList = async () => {
    try {
      setloading(true)
      dispatch(updateIsLoading(true));
      const response: any = await fetchData();
      // throw 'error'
      if (response && response?.data && response?.data?.customers) {
        setCustomersListCount(response?.data?.count);

        if (!response?.data?.customers.length) {
          setCustomersList(null);
        } else {
          setCustomersList(response?.data?.customers);
        }

        setIsEmpty(false);
        dispatch(updateIsLoading(false));
      } else {
        dispatch(updateIsLoading(false));
      }
    } catch (e: any) {
      setCustomersList(null)
      dispatch(updateIsLoading(false));
    }
    setloading(false)
  };
  useEffect(() => {
    scrollTabDataRef.current?.scroll({ top: 0, behavior: "smooth" });
  }, [currentPage]);
  useEffect(() => {
    getList();
  }, [searchText, currentPage]);
  useEffect(() => {
    if (isDataUpdated) {
      getList(); //fetching special pricing list whenever the data is updated or deleted
      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);
  const handlePagination = (e: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__tableSection}>

          <div className={styles.tabs}>
            <div className={styles.border}></div>
            {(customersListCount > 10 || searchText?.length > 0) && (
              <PageSearch
                handleSearch={handleSearch}
                customStyles={searchCustomStyles}
                customPlaceHolder={`Search by customer email`}
              />
            )}
          </div>
          <div className={`${styles.tableContainer} ${!customersList && styles.emptyView} `}>

            {customersList && customersList?.length > 0 && (
              <>
                <div className={styles.header}>
                  <div
                    className={`${styles.cell} ${styles.heading}`}
                  >
                    Email
                  </div>
                  <div className={`${styles.cell} ${styles.heading}`}>First Name</div>
                  <div className={`${styles.cell} ${styles.heading}`}>Last Name</div>

                  <div className={`${styles.cell} ${styles.heading}`}></div>
                </div>
                <div className={styles.tabslistContainer}>
                  <div
                    ref={scrollTabDataRef}
                    className={`${styles.customerList} ${styles.overrideOrdersList}`}
                  >
                    {customersList?.map((customer: any) => (
                      <CustomerListCard
                        id={customer?.id}
                        email={customer?.email}
                        firstName={customer?.first_name}
                        lastName={customer?.last_name}
                        isEmailVerified={customer?.is_email_verified}
                        setIsDataUpdated={setIsDataUpdated}
                      />
                    ))}
                  </div>

                  <div className={styles.paginationContainer}>
                    <PaginationComponent
                      count={Math.ceil(customersListCount / itemsPerPage)}
                      page={currentPage}
                      handlePagination={handlePagination}
                      showFirstButton={true}
                      showLastButton={true}
                    />
                  </div>
                </div>

              </>
            )}

            {
              !loading && !customersList && !customersList?.length && <EmptyContainer title={"Woah!"} subText={"No customer"} />
            }
          </div>

        </div>
      </div>
    </>
  );
};
export default CustomerList;
