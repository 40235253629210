import { DataGrid, GridColDef } from "@mui/x-data-grid";
import PaginationComponent from "../../../../common/components/pagination/Pagination";
import styles from "../AlgoliaSearch.module.scss";
import { IAlgoliaSearchResults } from "../algoliaSearch";
import { useState } from "react";

type ResultsTableProps = {
  columns: GridColDef[];
  reportData: IAlgoliaSearchResults[];
  loading?: boolean;
};

function ResultsTable({ columns, reportData, loading }: ResultsTableProps) {
  const [page, setPage] = useState<number>(1);

  return (
    <section className={styles.tableContainer}>
      <DataGrid
        rows={reportData.slice(
          10 * (page - 1),
          10 * page > reportData.length ? reportData.length : 10 * page
        )}
        columns={columns}
        hideFooterPagination
        hideFooter
        loading={loading}
        sx={{
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
        }}
      />
      <div className={styles.paginationContainer}>
        <PaginationComponent
          count={Math.ceil(reportData.length / 10)}
          handlePagination={(_, page) => {
            setPage(page);
          }}
          page={page}
          showFirstButton={true}
          showLastButton={true}
        />
      </div>
    </section>
  );
}

export default ResultsTable;
