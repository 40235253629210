/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import styles from "./CustomerListingStyles.module.scss";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import React from "react";
import infoIconBlack from "../../../pages/buyer/myCart/images/infoIconBlack.svg";
import CustomerList from "./components/customerList/CustomerList";

const CustomerListing = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const popperRef = useRef<any>();

  useEffect(() => {
    if (popperRef.current) {
      setAnchorEl(popperRef.current);
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, 5000);
  }, []);
  const [isEmpty, setIsEmpty] = useState(false);
  return (
    <>
      <div className={styles.specialPricing}>
        <div className={styles.right}>
          <div className={styles.header}>
            <div className={styles.leftPart}>
              <div className={styles.heading}>Customer list</div>

            </div>

          </div>
          <CustomerList setIsEmpty={setIsEmpty} />
        </div>
      </div>
    </>
  );
};
export default CustomerListing;
