/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Dropdown from "../../../common/components/dropdown/Dropdown";
import InputField from "../../../common/components/formFields/inputField/inputField";
import MobileNumberField from "../../../common/components/formFields/mobileNumberField/mobileNumberField";
import { constants } from "../../../common/constants/constants";
import { routeConfig } from "../../../common/constants/routeConfig";
import styles from "./AdminFinanceFormStyles.module.scss";
import useFetchCountries from "../../../services/useCountry";
import { ICountry } from "../../../models/IAddIndividualProductForm";
import { useAxios } from "../../../services/useAxios";
import { ToastNotification } from "../../../common/components/toastNotification/ToastNotification";
import errorToast from "../../../assets/images/errorToast.svg";
import successToast from "../../../assets/images/successToast.svg";
import { setToastStatusApproval } from "../../../reducerSlices/toastStatusSlice";
import { useDispatch } from "react-redux";
import DatePickerComponent from "../../../common/components/datePicker/DatePicker";

function AdminFinanceForm() {
  const { countriesList } = useFetchCountries();
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [emiratesList, setEmiratesList] = useState<ICountry[]>([]);
  const [selectedEmirate, setSelectedEmirate] = useState<ICountry>();
  const [mobileNumberProductCode, setMobileNumberProductCode] = useState<any>();
  const [toastData, setToastData] = useState(constants.defaultToastData);
  const [editForm, setEditForm] = useState(false);
  const { adminId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [incorporationDate, setIncorporationDate] = useState<any>();
  const [expiryDate, setExpiryDate] = useState<any>();
  const [dob, setDob] = useState<any>()
  const schema = yup
    .object({
      aggregateReferenceNumber: yup.number()
        .required("Enter Aggregate Reference Number"),
      firstName: yup.string().required("Enter first name"),
      lastName: yup.string().required("Enter last name"),
      middleName: yup.string().required("Enter middle name"),

      emailID: yup
        .string()
        .required("Enter email id")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter valid email"),
      vendor_type: yup.string().required("Enter vendor type"),
      business_name: yup.string().required("Enter business name"),
      business_trade_license_no: yup.number().required("Enter business_trade_license_no"),
      phNo: yup
        .number()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      countryName: yup.object().required("Select country"),
      email_address: yup
        .string()
        .required("Enter email address")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter valid email"),

      license_issuing_authority: yup.string().required("Enter license_issuing_authority"),
      annual_revenue: yup.number().required("Enter annual_revenue"),
      tax_registration_number: yup.string().required("Enter tax_registration_number"),
      annual_vat_paid: yup.string().required("Enter annual_vat_paid"),
      business_structure: yup.string().required("Enter business_structure"),
      business_industry: yup.string().required("Enter business_industry"),
      business_address_address: yup.string().required("Enter business_address_address"),
      business_address_city: yup.string().required("Enter business_address_city"),
      business_address_emirate: yup.string().required("Enter business_address_emirate"),
      business_address_building_name: yup.string().required("Enter business_address_building_name"),
      business_address_street_no: yup.string().required("Enter business_address_street_no"),
      business_address_office_no: yup.string().required("Enter business_address_office_no"),
      business_address_postal_code: yup.number().required("Enter business_address_postal_code"),

      business_info_phNo: yup
        .number()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      business_info_countryName: yup.object().required("Select country"),

      mailing_address_address: yup.string().required("Enter mailing_address_address"),
      mailing_address_city: yup.string().required("Enter mailing_address_city"),
      mailing_address_emirate: yup.string().required("Enter mailing_address_emirate"),
      mailing_address_building_name: yup.string().required("Enter mailing_address_building_name"),
      mailing_address_street_no: yup.string().required("Enter mailing_address_street_no"),
      mailing_address_office_no: yup.string().required("Enter mailing_address_office_no"),
      mailing_address_postal_code: yup.number().required("Enter mailing_address_postal_code"),

      no_of_warehouses: yup.string().required("Enter no_of_warehouses"),
      owner_info_first_name: yup.string().required("Enter owner_info_first_name"),
      owner_info_last_name: yup.string().required("Enter owner_info_last_name"),
      owner_info_email: yup
        .string()
        .required("Enter owner_info_email")
        .matches(constants.formaValidations.EMAIL_REGEX, "Enter valid owner_info_email"),


      owner_info_phNo: yup
        .number()
        .required("Enter phone number")
        .typeError("Enter a valid phone number"),
      owner_info_countryName: yup.object().required("Select country"),

      ownership_percentage: yup.string().required("Enter ownership_percentage"),

      owner_info_address_address: yup.string().required("Enter owner_info_address_address"),
      owner_info_address_city: yup.string().required("Enter owner_info_city"),
      owner_info_address_emirate: yup.string().required("Enter owner_info_emirate"),
      owner_info_address_building_name: yup.string().required("Enter owner_info_building_name"),
      owner_info_address_street_no: yup.string().required("Enter owner_info_street_no"),
      owner_info_address_office_no: yup.string().required("Enter owner_info_office_no"),
      owner_info_address_postal_code: yup.number().required("Enter owner_info_postal_code"),

      title_at_company: yup.string().required("Enter title_at_company"),
      role_at_business: yup.string().required("Enter role_at_business"),
      emirate_id: yup.number().required("Enter emirate_id"),

      purchased_by: yup.string().required("Enter the purchaser's name"),
      purchased_from: yup.string().required("Enter the seller's name"),
      products_info: yup.string().required("Enter the product information"),
      total_cost_amount: yup.number().required("Enter the total cost amount").positive("Amount must be positive"),
      total_net_amount: yup.number().required("Enter the total net amount").positive("Amount must be positive"),
      vat_amount: yup.number().required("Enter the VAT amount").positive("Amount must be positive"),
      delivery_fee: yup.number().required("Enter the delivery fee").positive("Amount must be positive"),
      total_payable_amount: yup.number().required("Enter the total payable amount").positive("Amount must be positive"),
      delivery_address: yup.string().required("Enter the delivery address"),
      billing_address: yup.string().required("Enter the billing address"),


    })
    .required();

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    trigger,
    getValues,
    // trigger,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const values = getValues();
  console.log(errors, "errorserrorserrorserrorserrorserrorserrors")
  const onChangeField = React.useCallback(
    (name: any) => (text: any) => {
      setValue(name, text);
      // trigger(name)
      isSubmitted && trigger(name)
    },
    [setValue, isSubmitted]
  );

  const getData = useAxios({
    axiosParams: {
      url: `admin/user/${adminId}`,
    },
    method: "GET",
  });

  const updateAdminData = useAxios({
    axiosParams: {
      url: `admin/user/${adminId}`,
      data: {

      },
    },
    method: "PUT",
  });

  const [currentAdminData, setCurrentAdminData] = useState<any>({});
  useEffect(() => {
    const getFlag: any =
      currentAdminData &&
      countriesList &&
      countriesList.length > 0 &&
      countriesList.filter(
        (country) =>
          country?.dial_codes ===
          (currentAdminData?.phone_number?.split("#")[0] as any)
      )[0];
    if (getFlag) {
      setMobileNumberProductCode({
        dial_codes: currentAdminData?.phone_number?.split("#")[0],
        flag: getFlag && getFlag?.flag,
      });
    }
  }, [countriesList, currentAdminData]);

  const postAdminData = useAxios({
    axiosParams: {
      url: "admin/user/create/finance",
      data: {
        aggregate_reference_number: values.aggregateReferenceNumber,
        lead_info: {
          first_name: values.firstName,
          last_name: values.lastName,
          middle_name: values.middleName,
          email: values.emailID,
          mobile: values.phNo,
        },
        business_info: {
          vendor_type: values.vendor_type,
          business_name: values.business_name,
          business_incorporation_date: incorporationDate,
          trade_license_expiry_date: expiryDate,
          business_trade_license_no: values.business_trade_license_no,
          business_phone_no: values.business_info_phNo,
          email_address: values.email_address,
          license_issuing_authority: values.license_issuing_authority,
          annual_revenue: values.annual_revenue,
          tax_registration_number: values.tax_registration_number,
          annual_vat_paid: values.annual_vat_paid,
          business_structure: values.business_structure,
          business_industry: values.business_industry,
          business_address: {
            "address": values.business_address_address,
            "city": values.business_address_city,
            "emirate": values.business_address_emirate,
            "building_name": values.business_address_building_name,
            "street_no": values.business_address_street_no,
            "office_no": values.business_address_office_no,
            "postal_code": values.business_address_postal_code
          },
          mailing_address: {
            "address": values.mailing_address_address,
            "city": values.mailing_address_city,
            "emirate": values.mailing_address_emirate,
            "building_name": values.mailing_address_building_name,
            "street_no": values.mailing_address_street_no,
            "office_no": values.mailing_address_office_no,
            "postal_code": values.mailing_address_postal_code
          },
          no_of_warehouses: values.no_of_warehouses
        },

        "owner_info": [
          {
            "first_name": values.owner_info_first_name,
            "last_name": values.owner_info_last_name,
            "email": values.owner_info_email,
            "phone": values.owner_info_phNo,
            "date_of_birth": dob,
            "ownership_percentage": values.ownership_percentage,
            "address": {
              "address": values.owner_info_address_address,
              "city": values.owner_info_address_city,
              "emirate": values.owner_info_address_emirate,
              "building_name": values.owner_info_address_building_name,
              "street_no": values.owner_info_address_street_no,
              "office_no": values.owner_info_address_office_no,
              "postal_code": values.owner_info_address_postal_code
            },
            "title_at_company": values.title_at_company,
            "role_at_business": values.role_at_business,
            "emirate_id": values.emirate_id
          }
        ],
        "orders": [
          {
            "purchased_by": values.purchased_by,
            "purchased_from": values.purchased_from,
            "products_info": values.products_info,
            "total_cost_amount": values.total_cost_amount,
            "total_net_amount": values.total_net_amount,
            "vat_amount": values.vat_amount,
            "delivery_fee": values.delivery_fee,
            "total_payable_amount": values.total_payable_amount,
            "delivery_address": values.delivery_address,
            "billing_address": values.billing_address
          }
        ]
      }
    },
    method: "POST",
  });

  useEffect(() => {
    if (updateAdminData?.error) {
      setToastData({
        message: updateAdminData?.error?.message as any,
        status: "Failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }

    if (postAdminData?.error) {
      setToastData({
        message: postAdminData.error.message as any,
        status: "Failure",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  }, [updateAdminData?.error, postAdminData.error]);

  const updateAdminApiCall = async () => {
    const response: any = await updateAdminData.fetchData();
    if (response.status === "SUCCESS") {
      dispatch(
        setToastStatusApproval({
          message: "Finance has been updated successfully",
          status: "created",
          type: "create",
        } as any)
      );
      return response;
    } else {
      setToastData({
        message: response?.response?.data?.error_msg,
        status: "Fail",
      });
      setTimeout(() => {
        setToastData(constants.defaultToastData);
      }, 5000);
    }
  };

  const submit = async (data: any) => {
    if (data) {
      if (!adminId) {
        const response: any = await postAdminData.fetchData();
        if (response && response?.status === "SUCCESS") {
          console.log(response);
          dispatch(
            setToastStatusApproval({
              message: "Finance has been created successfully",
              status: "edited",
              type: "edit",
            } as any)
          );
          reset();
          navigate(routeConfig.adminAddBuyer);
        }
      } else {
        const response: any = await updateAdminApiCall();
        if (response && response?.status === "SUCCESS") {
          navigate(routeConfig.adminAddBuyer);
        }
      }
    }
  };

  const handleStartDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      setIncorporationDate(selectedDate);
    } else if (selectedDate == null) {
      setIncorporationDate(null);
    }
  };


  const handleExpiryDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      setExpiryDate(selectedDate);
    } else if (selectedDate == null) {
      setExpiryDate(null);
    }
  };

  const handleDOBDateChange = (selectedDate: Date | null) => {
    if (selectedDate) {
      setDob(selectedDate);
    } else if (selectedDate == null) {
      setDob(null);
    }
  };
  return (
    <div className={styles.businessSetupForm}>
      <div className={styles.right}>
        <div className={styles.top}>
          <div className={styles.greetings}>
            <div className={styles.user}>
              Create Finance
            </div>
          </div>
          <div className={styles.actionsOnUser}>
            {adminId && !editForm && (
              <button
                className={styles.submit}
                type="button"
                onClick={() => setEditForm(!editForm)}
              >
                Edit Form
              </button>
            )}
          </div>
        </div>
        <form className={styles.bottom} onSubmit={handleSubmit(submit)}>
          <div className={styles.form}>
            <div className={styles.businessSetup}>
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("aggregateReferenceNumber")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="aggregateReferenceNumber"
                    label={`Aggregate Reference Number`}
                    placeholder="Enter aggregate reference number"
                    onChange={onChangeField("aggregateReferenceNumber")}
                    register={register("aggregateReferenceNumber")}
                    errorMessage={errors.aggregateReferenceNumber?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("firstName")}
                    maxWidth="100%"
                    minWidth="250px"
                    disabled={adminId && !editForm}
                    id="firstName"
                    label={"First Name"}
                    placeholder="Enter first name"
                    onChange={onChangeField("firstName")}
                    register={register("firstName")}
                    errorMessage={errors.firstName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("middleName")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="middleName"
                    label={"Middle Name"}

                    placeholder="Enter middle name"
                    onChange={onChangeField("middleName")}
                    register={register("middleName")}
                    errorMessage={errors.middleName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    value={watch("lastName")}
                    minWidth="250px"
                    id="lastName"
                    label={"Last Name"}
                    placeholder="Enter last name"
                    onChange={onChangeField("lastName")}
                    register={register("lastName")}
                    errorMessage={errors.lastName?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="emailID"
                    disabled={adminId}
                    label="Email ID"
                    placeholder="Enter email here"
                    onChange={onChangeField("emailID")}
                    register={register("emailID")}
                    errorMessage={errors.emailID?.message}
                    value={watch("emailID")}
                  />
                </div>
                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      countriesList &&
                      countriesList.length > 0 &&
                      countriesList.filter(
                        (country) => country?.dial_codes === ("+971" as any)
                      )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    value={watch("phNo")}
                    label="Phone numbddder"
                    id={"phnNo"}
                    editForm={false}
                    options={countriesList}
                    registerMobileNumber={register("phNo")}
                    registerCountry={register("countryName")}
                    onMobileNumberChange={onChangeField("phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      onChangeField("countryName")(selectedOption);
                      setMobileNumberProductCode(selectedOption);
                    }}
                    errorMessage={
                      errors.phNo?.message && errors.phNo?.message.length > 60
                        ? `${errors.phNo?.message.slice(0, 60)}...`
                        : errors.phNo?.message
                    }
                    mobileNumberField={true}
                  />
                </div>



              </div>




              <h1>Business Info</h1>
              <br />
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("vendor_type")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="vendor_type"
                    label={`Vendor Type`}
                    placeholder="Enter Vendor Type"
                    onChange={onChangeField("vendor_type")}
                    register={register("vendor_type")}
                    errorMessage={errors.vendor_type?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("business_name")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="business_name"
                    label={"Business Name"}
                    placeholder="Enter business name"
                    onChange={onChangeField("business_name")}
                    register={register("business_name")}
                    errorMessage={errors.business_name?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <DatePickerComponent
                    key={`expiryDate`}
                    data-testid="datePickerStart"
                    date={expiryDate?.length ?? expiryDate}
                    setDate={setExpiryDate}
                    handleDateChange={handleExpiryDateChange}
                    showOuterError={true}
                    placeholder="Enter expiry date "
                    disablePast={false}
                    disableFuture={false}
                    maxWidth={600}
                  />
                </div>
                <div className={styles.inputField}>
                  <DatePickerComponent
                    key={`incorporationDate`}
                    data-testid="datePickerStart"
                    date={incorporationDate?.length ?? incorporationDate}
                    setDate={setIncorporationDate}
                    handleDateChange={handleStartDateChange}
                    showOuterError={true}
                    placeholder="Enter incorporation date "
                    disablePast={false}
                    disableFuture={false}
                    maxWidth={600}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    value={watch("business_trade_license_no")}
                    minWidth="250px"
                    id="business_trade_license_no"
                    label={"Business Trade License No"}
                    placeholder="Enter business trade license no"
                    onChange={onChangeField("business_trade_license_no")}
                    register={register("business_trade_license_no")}
                    errorMessage={errors.business_trade_license_no?.message}
                  />
                </div>




                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      countriesList &&
                      countriesList.length > 0 &&
                      countriesList.filter(
                        (country) => country?.dial_codes === ("+971" as any)
                      )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    value={watch("business_info_phNo")}
                    label="Phone number"
                    id={"business_info_phNo"}
                    options={countriesList}
                    editForm={false}
                    registerMobileNumber={register("business_info_phNo")}
                    registerCountry={register("business_info_countryName")}
                    onMobileNumberChange={onChangeField("business_info_phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      onChangeField("business_info_countryName")(selectedOption);
                    }}
                    errorMessage={
                      errors.business_info_phNo?.message && errors.business_info_phNo?.message.length > 60
                        ? `${errors.business_info_phNo?.message.slice(0, 60)}...`
                        : errors.business_info_phNo?.message
                    }
                    mobileNumberField={true}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="email_address"
                    disabled={adminId}
                    label="Email ID"
                    placeholder="Enter email here"
                    onChange={onChangeField("email_address")}
                    register={register("email_address")}
                    errorMessage={errors.email_address?.message}
                    value={watch("email_address")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="license_issuing_authority"
                    label="License Issuing Authority"
                    placeholder="Enter license issuing authority here"
                    onChange={onChangeField("license_issuing_authority")}
                    register={register("license_issuing_authority")}
                    errorMessage={errors.license_issuing_authority?.message}
                    value={watch("license_issuing_authority")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="annual_revenue"
                    label="Annual Revenue"
                    placeholder="Enter annual revenue here"
                    onChange={onChangeField("annual_revenue")}
                    register={register("annual_revenue")}
                    errorMessage={errors.annual_revenue?.message}
                    value={watch("annual_revenue")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="tax_registration_number"
                    label="Tax Registration Number"
                    placeholder="Enter tax registration number here"
                    onChange={onChangeField("tax_registration_number")}
                    register={register("tax_registration_number")}
                    errorMessage={errors.tax_registration_number?.message}
                    value={watch("tax_registration_number")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="annual_vat_paid"
                    label="Annual Vat Paid"
                    placeholder="Enter annual vat paid here"
                    onChange={onChangeField("annual_vat_paid")}
                    register={register("annual_vat_paid")}
                    errorMessage={errors.annual_vat_paid?.message}
                    value={watch("annual_vat_paid")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_structure"
                    label="Business Structure"
                    placeholder="Enter business structure here"
                    onChange={onChangeField("business_structure")}
                    register={register("business_structure")}
                    errorMessage={errors.business_structure?.message}
                    value={watch("business_structure")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_industry"
                    label="Business Industry"
                    placeholder="Enter business_industry here"
                    onChange={onChangeField("business_industry")}
                    register={register("business_industry")}
                    errorMessage={errors.business_industry?.message}
                    value={watch("business_industry")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_address"
                    label="Business Address"
                    placeholder="Enter business address here"
                    onChange={onChangeField("business_address_address")}
                    register={register("business_address_address")}
                    errorMessage={errors.business_address_address?.message}
                    value={watch("business_address_address")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_city"
                    label="Business City"
                    placeholder="Enter business city here"
                    onChange={onChangeField("business_address_city")}
                    register={register("business_address_city")}
                    errorMessage={errors.business_address_city?.message}
                    value={watch("business_address_city")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_emirate"
                    label="Business Emirate"
                    placeholder="Enter business emirate here"
                    onChange={onChangeField("business_address_emirate")}
                    register={register("business_address_emirate")}
                    errorMessage={errors.business_address_emirate?.message}
                    value={watch("business_address_emirate")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_building_name"
                    label="Business Building Name"
                    placeholder="Enter business building name here"
                    onChange={onChangeField("business_address_building_name")}
                    register={register("business_address_building_name")}
                    errorMessage={errors.business_address_building_name?.message}
                    value={watch("business_address_building_name")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_street_no"
                    label="Business Street No"
                    placeholder="Enter business street no here"
                    onChange={onChangeField("business_address_street_no")}
                    register={register("business_address_street_no")}
                    errorMessage={errors.business_address_street_no?.message}
                    value={watch("business_address_street_no")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_office_no"
                    label="Business Office No."
                    placeholder="Enter business office no here"
                    onChange={onChangeField("business_address_office_no")}
                    register={register("business_address_office_no")}
                    errorMessage={errors.business_address_office_no?.message}
                    value={watch("business_address_office_no")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="business_address_postal_code"
                    label="Business Postal Code"
                    placeholder="Enter business postal code here"
                    onChange={onChangeField("business_address_postal_code")}
                    register={register("business_address_postal_code")}
                    errorMessage={errors.business_address_postal_code?.message}
                    value={watch("business_address_postal_code")}
                  />
                </div>




                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_address"
                    label="Mailing Address"
                    placeholder="Enter Miling address here"
                    onChange={onChangeField("mailing_address_address")}
                    register={register("mailing_address_address")}
                    errorMessage={errors.mailing_address_address?.message}
                    value={watch("mailing_address_address")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_city"
                    label="Mailing City"
                    placeholder="Enter mailing city here"
                    onChange={onChangeField("mailing_address_city")}
                    register={register("mailing_address_city")}
                    errorMessage={errors.mailing_address_city?.message}
                    value={watch("mailing_address_city")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_emirate"
                    label="Mailing Emirate"
                    placeholder="Enter mailing emirate here"
                    onChange={onChangeField("mailing_address_emirate")}
                    register={register("mailing_address_emirate")}
                    errorMessage={errors.mailing_address_emirate?.message}
                    value={watch("mailing_address_emirate")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_building_name"
                    label="Mailing Building Name"
                    placeholder="Enter mailing building name here"
                    onChange={onChangeField("mailing_address_building_name")}
                    register={register("mailing_address_building_name")}
                    errorMessage={errors.mailing_address_building_name?.message}
                    value={watch("mailing_address_building_name")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_street_no"
                    label="Mailing Street No"
                    placeholder="Enter mailing street no here"
                    onChange={onChangeField("mailing_address_street_no")}
                    register={register("mailing_address_street_no")}
                    errorMessage={errors.mailing_address_street_no?.message}
                    value={watch("mailing_address_street_no")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_office_no"
                    label="Mailing Office No."
                    placeholder="Enter mailing office no here"
                    onChange={onChangeField("mailing_address_office_no")}
                    register={register("mailing_address_office_no")}
                    errorMessage={errors.mailing_address_office_no?.message}
                    value={watch("mailing_address_office_no")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="mailing_address_postal_code"
                    label="Mailing Postal Code"
                    placeholder="Enter mailing postal code here"
                    onChange={onChangeField("mailing_address_postal_code")}
                    register={register("mailing_address_postal_code")}
                    errorMessage={errors.mailing_address_postal_code?.message}
                    value={watch("mailing_address_postal_code")}
                  />
                </div>






                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="no_of_warehouses"
                    label="No. of Warehouses"
                    placeholder="Enter no of warehouses here"
                    onChange={onChangeField("no_of_warehouses")}
                    register={register("no_of_warehouses")}
                    errorMessage={errors.no_of_warehouses?.message}
                    value={watch("no_of_warehouses")}
                  />
                </div>








              </div>




              <h1>Owner Info</h1>
              <br />
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("owner_info_first_name")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="owner_info_first_name"
                    label={`First Name`}
                    placeholder="Enter First Name"
                    onChange={onChangeField("owner_info_first_name")}
                    register={register("owner_info_first_name")}
                    errorMessage={errors.owner_info_first_name?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("owner_info_last_name")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="owner_info_last_name"
                    label={`Last Name`}
                    placeholder="Enter Last Name"
                    onChange={onChangeField("owner_info_last_name")}
                    register={register("owner_info_last_name")}
                    errorMessage={errors.owner_info_last_name?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("owner_info_email")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="owner_info_email"
                    label={`Email`}
                    placeholder="Enter Email"
                    onChange={onChangeField("owner_info_email")}
                    register={register("owner_info_email")}
                    errorMessage={errors.owner_info_email?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <MobileNumberField
                    defaultValue={
                      countriesList &&
                      countriesList.length > 0 &&
                      countriesList.filter(
                        (country) => country?.dial_codes === ("+971" as any)
                      )[0]
                    }
                    width="100%"
                    overlayWidth={"300px"}
                    value={watch("owner_info_phNo")}
                    label="Phone number"
                    id={"owner_info_phnNo"}
                    options={countriesList}
                    editForm={false}
                    // editForm={!editForm && adminId ? true : false}
                    registerMobileNumber={register("owner_info_phNo")}
                    registerCountry={register("owner_info_countryName")}
                    onMobileNumberChange={onChangeField("owner_info_phNo")}
                    onCountryChange={(selectedOption: ICountry) => {
                      onChangeField("owner_info_countryName")(selectedOption);
                    }}
                    errorMessage={
                      errors.business_info_phNo?.message && errors.business_info_phNo?.message.length > 60
                        ? `${errors.business_info_phNo?.message.slice(0, 60)}...`
                        : errors.business_info_phNo?.message
                    }
                    mobileNumberField={true}
                  />
                </div>


                <div className={styles.inputField}>
                  <DatePickerComponent
                    key={`date_of_birth`}
                    data-testid="datePickerStart"
                    date={dob?.length ?? dob}
                    setDate={setDob}
                    handleDateChange={handleDOBDateChange}
                    showOuterError={true}
                    placeholder="Enter date of birth "
                    disablePast={false}
                    disableFuture={false}
                    maxWidth={600}
                  />
                </div>

                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    value={watch("ownership_percentage")}
                    minWidth="250px"
                    id="ownership_percentage"
                    label={"Business Ownership"}
                    placeholder="Enter ownership"
                    onChange={onChangeField("ownership_percentage")}
                    register={register("ownership_percentage")}
                    errorMessage={errors.ownership_percentage?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_address"
                    label="Business Address"
                    placeholder="Enter address here"
                    onChange={onChangeField("owner_info_address_address")}
                    register={register("owner_info_address_address")}
                    errorMessage={errors.owner_info_address_address?.message}
                    value={watch("owner_info_address_address")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_city"
                    label="Business City"
                    placeholder="Enter city here"
                    onChange={onChangeField("owner_info_address_city")}
                    register={register("business_address_city")}
                    errorMessage={errors.owner_info_address_city?.message}
                    value={watch("owner_info_address_city")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_emirate"
                    label="Business Emirate"
                    placeholder="Enter emirate here"
                    onChange={onChangeField("owner_info_address_emirate")}
                    register={register("owner_info_address_emirate")}
                    errorMessage={errors.owner_info_address_emirate?.message}
                    value={watch("owner_info_address_emirate")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_building_name"
                    label="Business Building Name"
                    placeholder="Enter building name here"
                    onChange={onChangeField("owner_info_address_building_name")}
                    register={register("owner_info_address_building_name")}
                    errorMessage={errors.owner_info_address_building_name?.message}
                    value={watch("owner_info_address_building_name")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_street_no"
                    label="Business Street No"
                    placeholder="Enter  street no here"
                    onChange={onChangeField("owner_info_address_street_no")}
                    register={register("owner_info_address_street_no")}
                    errorMessage={errors.owner_info_address_street_no?.message}
                    value={watch("owner_info_address_street_no")}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_office_no"
                    label="Business Office No."
                    placeholder="Enter office no here"
                    onChange={onChangeField("owner_info_address_office_no")}
                    register={register("owner_info_address_office_no")}
                    errorMessage={errors.owner_info_address_office_no?.message}
                    value={watch("owner_info_address_office_no")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="owner_info_address_postal_code"
                    label="Business Postal Code"
                    placeholder="Enter postal code here"
                    onChange={onChangeField("owner_info_address_postal_code")}
                    register={register("owner_info_address_postal_code")}
                    errorMessage={errors.owner_info_address_postal_code?.message}
                    value={watch("owner_info_address_postal_code")}
                  />
                </div>





                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="title_at_company"
                    label="Title at Company"
                    placeholder="Enter title at company here"
                    onChange={onChangeField("title_at_company")}
                    register={register("title_at_company")}
                    errorMessage={errors.title_at_company?.message}
                    value={watch("title_at_company")}
                  />
                </div>

                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="role_at_business"
                    label="Role at business"
                    placeholder="Enter role at business here"
                    onChange={onChangeField("role_at_business")}
                    register={register("role_at_business")}
                    errorMessage={errors.role_at_business?.message}
                    value={watch("role_at_business")}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    maxWidth="100%"
                    id="emirate_id"
                    label="Emirate Id"
                    placeholder="Enter emirate id here"
                    onChange={onChangeField("emirate_id")}
                    register={register("emirate_id")}
                    errorMessage={errors.emirate_id?.message}
                    value={watch("emirate_id")}
                  />
                </div>







              </div>




              <h1>Orders</h1>
              <br />
              <div className={styles.inputFields}>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("purchased_by")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="purchased_by"
                    label={`Purchased by`}
                    placeholder="Enter purchased by"
                    onChange={onChangeField("purchased_by")}
                    register={register("purchased_by")}
                    errorMessage={errors.purchased_by?.message}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    value={watch("purchased_from")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="purchased_from"
                    label={`Purchased From`}
                    placeholder="Enter purchased from Name"
                    onChange={onChangeField("purchased_from")}
                    register={register("purchased_from")}
                    errorMessage={errors.purchased_from?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("total_cost_amount")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="total_cost_amount"
                    label={`Total Cost Amount`}
                    placeholder="Enter total cost amount"
                    onChange={onChangeField("total_cost_amount")}
                    register={register("total_cost_amount")}
                    errorMessage={errors.total_cost_amount?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("total_net_amount")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="total_net_amount"
                    label={`Total Net Amount`}
                    placeholder="Enter total net amount"
                    onChange={onChangeField("total_net_amount")}
                    register={register("total_net_amount")}
                    errorMessage={errors.total_net_amount?.message}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    value={watch("vat_amount")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="vat_amount"
                    label={`vat amount`}
                    placeholder="Enter vat amount"
                    onChange={onChangeField("vat_amount")}
                    register={register("vat_amount")}
                    errorMessage={errors.vat_amount?.message}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    value={watch("products_info")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="products_info"
                    label={`Products Info`}
                    placeholder="Enter products info"
                    onChange={onChangeField("products_info")}
                    register={register("products_info")}
                    errorMessage={errors.products_info?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("delivery_fee")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="delivery_fee"
                    label={`Delivery Fee`}
                    placeholder="Enter delivery fee"
                    onChange={onChangeField("delivery_fee")}
                    register={register("delivery_fee")}
                    errorMessage={errors.delivery_fee?.message}
                  />
                </div>



                <div className={styles.inputField}>
                  <InputField
                    value={watch("total_payable_amount")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="total_payable_amount"
                    label={`Total Payable amount`}
                    placeholder="Enter total payable amount"
                    onChange={onChangeField("total_payable_amount")}
                    register={register("total_payable_amount")}
                    errorMessage={errors.total_payable_amount?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("delivery_address")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="delivery_address"
                    label={`Delivery Address`}
                    placeholder="Enter delivery address"
                    onChange={onChangeField("delivery_address")}
                    register={register("delivery_address")}
                    errorMessage={errors.delivery_address?.message}
                  />
                </div>


                <div className={styles.inputField}>
                  <InputField
                    value={watch("billing_address")}
                    maxWidth="100%"
                    minWidth="250px"
                    id="billing_address"
                    label={`Billing Address`}
                    placeholder="Enter Billing Address"
                    onChange={onChangeField("billing_address")}
                    register={register("billing_address")}
                    errorMessage={errors.billing_address?.message}
                  />
                </div>




              </div>


            </div>
          </div>
          <div className={styles.action}>
            <button
              className={styles.skip}
              onClick={() => navigate(routeConfig.adminAddBuyer)}
            >
              Cancel
            </button>
            <button className={styles.submit} type="submit" onClick={handleSubmit(submit)}>
              Create
            </button>
          </div>
        </form>
      </div>
      {toastData?.message ? (
        <ToastNotification
          icon={toastData.status === "Success" ? successToast : errorToast}
          content={toastData?.message}
          position={"top-right"}
          autoClose={5000}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AdminFinanceForm;
